export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
}
// export function isDefined(str) {
//   return str !== undefined
// }

export function validateLength(str, minLength = 1, trim = false) {
  return str && (trim ? str.trim().length >= minLength : str.length >= minLength)
}
export function validateUpper(str) {
  return str && str.match(/^(?=.*?[A-Z]).*$/)
}
export function validateLower(str) {
  return str && str.match(/^(?=.*?[a-z]).*$/)
}
export function validateDigit(str) {
  return str && str.match(/^(?=.*?[0-9]).*$/)
}
// export function validateSpecial(str) {
//   return str && str.match(/^(?=.*?[\\/().#?!@$%^&*-]).*$/)
// }
// https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
export function validatePassword(password, lower = false, upper = false, special = false, minLength = 8) {
  const regex = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{${minLength},}$`)
  return String(password).match(regex)
  // return String(email)
  //   .toLowerCase()
  //   .match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)
}
