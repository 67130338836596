/** @format */

import { Form } from "react-bootstrap"

import { buildGenericError } from "./generic_forms/GenericFormUtils"

export const BusinessTypes = Object.freeze({
  VET: 1,
  GROOMER: 2,
  BREEDER: 3,
  TRAINER: 4,
  OTHER: 99,
})

export const BusinessEditForms = Object.freeze({
  INFO: "info",
  ADDRESS: "address",
  CONTACTS: "contacts",
  SERVICES: "services",
  MEDICAL_SERVICES: "medical_services",
})

export const kEditFormLabel = Object.freeze({
  [BusinessEditForms.INFO]: "Info generali",
  [BusinessEditForms.ADDRESS]: "Indirizzo e mappa",
  [BusinessEditForms.CONTACTS]: "Contatti e reperibilità",
  [BusinessEditForms.SERVICES]: "Servizi",
  [BusinessEditForms.MEDICAL_SERVICES]: "Servizi medici",
})

export const busPetTypes = Object.freeze({
  [BusinessTypes.VET]: ["small", "horse", "exotic", "farm"],
  [BusinessTypes.GROOMER]: ["dog", "cat", "rabbit"],
  [BusinessTypes.BREEDER]: ["dog", "cat", "exotic", "horse", "farm"],
  [BusinessTypes.TRAINER]: ["dog", "cat"],
  [BusinessTypes.OTHER]: [],
})

export const busNames = Object.freeze({
  [BusinessTypes.VET]: "Veterinario",
  [BusinessTypes.GROOMER]: "Toelettatore",
  [BusinessTypes.BREEDER]: "Allevatore",
  [BusinessTypes.TRAINER]: "Educatore",
  [BusinessTypes.OTHER]: "Altro",
})

export const kVisiblePages = Object.freeze({
  [BusinessTypes.VET]: [BusinessEditForms.INFO, BusinessEditForms.ADDRESS, BusinessEditForms.CONTACTS, BusinessEditForms.SERVICES, BusinessEditForms.MEDICAL_SERVICES],
  [BusinessTypes.GROOMER]: [BusinessEditForms.INFO, BusinessEditForms.ADDRESS, BusinessEditForms.CONTACTS, BusinessEditForms.SERVICES],
  [BusinessTypes.BREEDER]: [BusinessEditForms.INFO, BusinessEditForms.ADDRESS, BusinessEditForms.CONTACTS, BusinessEditForms.SERVICES],
  [BusinessTypes.TRAINER]: [BusinessEditForms.INFO, BusinessEditForms.ADDRESS, BusinessEditForms.CONTACTS, BusinessEditForms.SERVICES],
  [BusinessTypes.OTHER]: [BusinessEditForms.INFO, BusinessEditForms.ADDRESS, BusinessEditForms.CONTACTS],
})

export const businessFieldNames = Object.freeze({
  type: "type",
  type_description: "type_description",
  title: "title",
  phone_primary: "phone.primary",
  address_street: "address.street",
  address_house_number: "address.house_number",
  address_city: "address.city",
  address_district: "address.district",
  address_postal_code: "address.postal_code",
  email: "email",
  geo_location: "address.geo.location",
  geo_lat: "address.geo.location.lat",
  geo_lng: "address.geo.location.lng",
  more_reach: "more.reach",
  more_appointment_only: "more.appointment_only",
  more_home_service: "more.home_service",
  more_home_service_range: "more.home_service_range",
  more_pet_type_cat: "more.pet_type.cat",
  more_pet_type_dog: "more.pet_type.dog",
  more_pet_type_exotic: "more.pet_type.exotic",
  more_pet_type_farm: "more.pet_type.farm",
  more_pet_type_horse: "more.pet_type.horse",
  more_pet_type_rabbit: "more.pet_type.rabbit",
  more_pet_type_small: "more.pet_type.small",
  more_self_service: "more.self_service",
  phones: "phones",
  NO_DB_form_address_check: "NO_DB.address.form_address_check",
  NO_DB_var_address_changed: "NO_DB.address.var_address_changed",
  NO_DB_var_marker_calculated: "NO_DB.address.var_marker_calculate",
  NO_DB_var_geocoder: "NO_DB.address.var_geocoder",
})

// if busTypes is defined it's used as a filter
// if busTypes is undefined it means all business use that field
export const businessInitialFields = {
  [businessFieldNames.type]: {
    init: undefined,
    error: "È necessario selezionare la categoria della tua attività",
  },
  [businessFieldNames.type_description]: {
    init: "",
    label: "Descrivi brevemente la categoria della tua attività",
    validate: true,
    error: "Campo obbligatorio",
  },
  [businessFieldNames.title]: {
    init: "",
    label: "Nome attività",
    validate: true,
    minLength: 5,
    error: "Minimo 5 caratteri",
  },
  [businessFieldNames.address_street]: {
    init: "",
    label: "Via",
    isAddressField: true,
    validate: true,
    error: " ",
  },
  [businessFieldNames.address_house_number]: {
    init: "",
    label: "Num. civico",
    isAddressField: true,
    validate: true,
    error: " ",
  },
  [businessFieldNames.address_city]: {
    init: "",
    label: "Comune",
    isAddressField: true,
    validate: true,
    error: " ",
  },
  [businessFieldNames.address_district]: {
    init: "",
    label: "Provincia",
    isAddressField: true,
    validate: true,
    error: " ",
  },
  [businessFieldNames.address_postal_code]: {
    init: "",
    label: "CAP",
    type: "number",
    isAddressField: true,
    validate: true,
    minLength: 5,
    error: "CAP incompleto",
  },

  [businessFieldNames.email]: {
    init: "",
    label: "Indirizzo e-mail",
    validate: true,
    error: "Campo obbligatorio",
    support: "@",
  },

  [businessFieldNames.more_reach]: {
    busTypes: [BusinessTypes.VET],
    validate: true,
    init: null,
    label: { 0: "No", 1: "Sì, su chiamata", 2: "Sì, presenza in loco" },
    type: "radio",
    error: "Campo obbligatorio",
  },
  [businessFieldNames.more_home_service]: {
    busTypes: [BusinessTypes.VET, BusinessTypes.GROOMER, BusinessTypes.TRAINER],
    init: false,
    label: "Servizio a domicilio",
    type: "switch",
  },
  [businessFieldNames.more_home_service_range]: {
    busTypes: [BusinessTypes.VET, BusinessTypes.GROOMER, BusinessTypes.TRAINER],
    init: 0,
    label: "Raggio (km)",
    type: "select",
  },
  [businessFieldNames.more_appointment_only]: {
    busTypes: [BusinessTypes.VET],
    init: false,
    label: "Visita solo su appuntamento",
    type: "switch",
  },
  [businessFieldNames.more_pet_type_cat]: { init: false, label: "Gatti", type: "switch" },
  [businessFieldNames.more_pet_type_dog]: { init: false, label: "Cani", type: "switch" },
  [businessFieldNames.more_pet_type_exotic]: {
    init: false,
    label: "Animali esotici",
    type: "switch",
  },
  [businessFieldNames.more_pet_type_farm]: {
    init: false,
    label: "Animali da reddito",
    type: "switch",
  },
  [businessFieldNames.more_pet_type_horse]: { init: false, label: "Cavalli", type: "switch" },
  [businessFieldNames.more_pet_type_rabbit]: { init: false, label: "Conigli", type: "switch" },
  [businessFieldNames.more_pet_type_small]: {
    init: false,
    label: "Piccoli animali",
    type: "switch",
  },
  [businessFieldNames.more_self_service]: {
    busTypes: [BusinessTypes.GROOMER],
    validate: true,
    error: "Campo obbligatorio",
    init: null,
    label: {
      0: "Nessun servizio self-service",
      1: "Servizio self-service (in orario di apertura)",
      2: "Servizio self-service H24",
    },
    type: "radio",
  },
  "phone.primary.num": {
    init: "",
    label: "Numero di telefono principale",
    type: "tel",
    validate: true,
    error: "Campo obbligatorio",
  },
  "phone.primary.note": {
    init: "",
    label: "Nota",
  },
  "phone.primary.main": {
    init: true,
  },
  "phone.primary.h24": {
    init: false,
    type: "switch",
    label: "Reperibilità H24",
  },
  "phone.secondary.num": {
    init: "",
    label: "Numero di telefono alternativo",
    type: "tel",
  },
  "phone.secondary.h24": {
    init: false,
    type: "switch",
    label: "Reperibilità H24",
  },
  "phone.secondary.note": {
    init: "",
    label: "Nota",
  },
  "owner.name": {
    init: "",
    label: "Nome",
    validate: true,
    error: "Campo obbligatorio",
  },
  "owner.surname": {
    init: "",
    label: "Cognome",
    validate: true,
    error: "Campo obbligatorio",
  },
  "owner.phone": {
    init: "",
    label: "Numero di telefono",
    type: "tel",
    validate: true,
    error: "Campo obbligatorio",
  },
}

//
export function getInitalAddForm() {
  let form = {}
  Object.keys(businessInitialFields).forEach((key) => {
    form[key] = businessInitialFields[key].init
  })
  return form
}

// from firebase draft data get form objs
export function initBusinessForm(data, section) {
  console.log("init form")
  switch (section) {
    case "info":
      return {
        [businessFieldNames.title]: data.title,
      }
    case "contacts":
      const obj = {
        phones: data.phones,
        email: data.email,
        "NO_DB.busType": data.type, // do not expose type in the form avoiding its editing
      }
      const extraFields = {}
      if (data.type === BusinessTypes.VET) {
        extraFields[businessFieldNames.more_reach] = data.more.reach
        extraFields[businessFieldNames.more_appointment_only] = data.more.appointment_only
      }
      return { ...obj, ...extraFields }
    case "address":
      return {
        [businessFieldNames.address_city]: data.address.city,
        [businessFieldNames.address_district]: data.address.district,
        [businessFieldNames.address_house_number]: data.address.house_number,
        [businessFieldNames.address_postal_code]: data.address.postal_code,
        [businessFieldNames.address_street]: data.address.street,
        [businessFieldNames.geo_location]: data.address.geo.location,
        [businessFieldNames.NO_DB_form_address_check]: false,
        [businessFieldNames.NO_DB_var_address_changed]: false,
        [businessFieldNames.NO_DB_var_marker_calculated]: true,
        //[businessFieldNames.NO_DB_var_geocoder]: undefined
      }
    case "services": {
      const obj = {}
      const extraFields = {}
      if (
        data.type === BusinessTypes.VET ||
        data.type === BusinessTypes.GROOMER ||
        data.type === BusinessTypes.TRAINER
      ) {
        extraFields[businessFieldNames.more_home_service] = data.more.home_service
        extraFields[businessFieldNames.more_home_service_range] = data.more.home_service_range
      }

      // for VET is in services_medical
      if (data.type === BusinessTypes.GROOMER || data.type === BusinessTypes.BREEDER || data.type === BusinessTypes.TRAINER) {
        busPetTypes[data.type].forEach((e) => {
          extraFields[businessFieldNames[`more_pet_type_${e}`]] = data.more.pet_type[e]
        })
      }

      if (data.type === BusinessTypes.GROOMER) {
        extraFields[businessFieldNames.more_self_service] = data.more.self_service
      } else if (data.type === BusinessTypes.TRAINER) {
      } else if (data.type === BusinessTypes.VET) {
      }
      return { ...obj, ...extraFields }
    }
    case "medical_services": {
      const obj = {}
      const extraFields = {}

      busPetTypes[data.type].forEach((e) => {
        extraFields[businessFieldNames[`more_pet_type_${e}`]] = data.more.pet_type[e]
      })

      return { ...obj, ...extraFields }
    }
    default:
      return {}
  }
}

// specific build error based on business type
export function buildBusinessFormErrors(obj, fields) {
  let selectedType = obj["type"]
  let locErrors = {}
  Object.keys(fields).forEach((key) => {
    if (fields[key].validate) {
      // validate if
      // no selectedType in form
      // no busTypes in field
      // busTypes in field containing selectedType
      if (!selectedType || (!!selectedType && (!fields[key].busTypes || fields[key].busTypes.includes(selectedType)))) {
        let error = buildGenericError(obj[key], fields[key])
        if (error) locErrors[key] = error
      }
    }
  })
  return locErrors
}

// export const businessInfoFieldsNew = {
//   type: {
//     id: "type",
//     error: "È necessario selezionare la categoria della tua attività",
//   },
//   title: {
//     id: "title",
//     label: "Nome attività",
//     validate: true,
//     minLength: 5,
//     error: "Minimo 5 caratteri",
//   },
//   address_street: {
//     id: "address.street",
//     label: "Via",
//     isAddressField: true,
//     validate: true,
//     error: " ",
//   },
//   address_houseNumber: {
//     id: "address.house_number",
//     label: "Num. civico",
//     isAddressField: true,
//     validate: true,
//     error: " ",
//   },
//   address_city: {
//     id: "address.city",
//     label: "Comune",
//     isAddressField: true,
//     validate: true,
//     error: " ",
//   },
//   address_district: {
//     id: "address.district",
//     label: "Provincia",
//     isAddressField: true,
//     validate: true,
//     error: " ",
//   },
//   address_postalCode: {
//     id: "address.postal_code",
//     label: "CAP",
//     type: "number",
//     isAddressField: true,
//     validate: true,
//     minLength: 5,
//     error: "CAP incompleto",
//   },
//   "phone.primary": {
//     label: "Numero di telefono principale",
//     type: "tel",
//     validate: true,
//     error: "Campo obbligatorio",
//   },
//   "phone.secondary": { label: "Numero di telefono altenrativo", type: "tel" },
//   "owner.name": { label: "Nome", validate: true, error: "Campo obbligatorio" },
//   "owner.surname": {
//     label: "Cognome",
//     validate: true,
//     error: "Campo obbligatorio",
//   },
//   "owner.phone": {
//     label: "Numero di telefono",
//     type: "tel",
//     validate: true,
//     error: "Campo obbligatorio",
//   },
// }

export const businessFormFields = {
  info: {
    [businessFieldNames.title]: businessInitialFields[businessFieldNames.title],
  },
  address: {
    [businessFieldNames.address_street]: businessInitialFields[businessFieldNames.address_street],
    [businessFieldNames.address_house_number]: businessInitialFields[businessFieldNames.address_house_number],
    [businessFieldNames.address_city]: businessInitialFields[businessFieldNames.address_city],
    [businessFieldNames.address_district]: businessInitialFields[businessFieldNames.address_district],
    [businessFieldNames.address_postal_code]: businessInitialFields[businessFieldNames.address_postal_code],
    [businessFieldNames.NO_DB_form_address_check]: {
      validate: true,
      error: " ",
      type: "bool",
    },
  },
}

// export const businessInfoFields = {
//   [businessFieldNames.title]: {
//     label: "Nome attività",
//     validate: true,
//     minLength: 5,
//     error: "Minimo 5 caratteri",
//   },
// }

// export const businessContactsFields = {
//   [businessFieldNames.phone_primary]: {
//     label: "Numero di telefono principale",
//     type: "tel",
//     validate: true,
//     error: "Campo obbligatorio",
//   },
//   "phone.secondary": { label: "Numero di telefono altenrativo", type: "tel" },
// }

// export const businessAddressFields = {
//   [businessFieldNames.address_street]: {
//     label: "Via",
//     isAddressField: true,
//     validate: true,
//     error: "Campo obbligatorio",
//   },
//   [businessFieldNames.address_house_number]: {
//     label: "Num. civico",
//     isAddressField: true,
//     validate: true,
//     error: "Campo obbligatorio",
//   },
//   [businessFieldNames.address_city]: {
//     label: "Comune",
//     isAddressField: true,
//     validate: true,
//     error: "Campo obbligatorio",
//   },
//   [businessFieldNames.address_district]: {
//     label: "Provincia",
//     isAddressField: true,
//     validate: true,
//     error: "Campo obbligatorio",
//   },
//   [businessFieldNames.address_postal_code]: {
//     label: "CAP",
//     type: "number",
//     isAddressField: true,
//     validate: true,
//     minLength: 5,
//     error: "CAP incompleto",
//   },
//   [businessFieldNames.form_address_check]: {
//     validate: true,
//     error: " ",
//     type: "bool",
//   },
// }

export function getSubSet(errors, tag) {
  const separator = "."
  const asArray = Object.entries(errors)
  // filter only tag
  const filtered = asArray.filter(([key, value]) => key.startsWith(tag + separator))
  errors = Object.fromEntries(filtered)
  return errors
}
