import React, { useState } from "react"
import { Form, Button, Alert, Card, Container, Col, Row, FloatingLabel } from "react-bootstrap"

import { useAuth } from "context/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import GoogleButtonLabel from "components/GoogleButtonLabel"
import Padding from "common/Padding"

export default function LogIn(props) {
  const redirectToDashboadOnLogin = props.redirectToDashboadOnLogin
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const { login, loginGoogle } = useAuth()

  const navigate = useNavigate()

  function goToDashboard() {
    navigate("/business/app/dashboard")
  }

  async function handleSubmit(e) {
    e.preventDefault()

    setError("")
    try {
      setLoading(true)
      await login(email, password)
      if (redirectToDashboadOnLogin) goToDashboard()
    } catch (e) {
      setError(e.code)
    }

    setLoading(false)
  }

  async function handleGoogle() {
    setError("")
    try {
      setLoading(true)
      await loginGoogle(email, password)
      if (redirectToDashboadOnLogin) goToDashboard()
    } catch (e) {
      setError(e.code)
    }
    setLoading(false)
  }

  return (
    <>
    <Padding>
      <Container   >
        <Row className="justify-content-center">
          <Col md={6} lg={5}>
            <Card className="p-4">
              <Card.Body>
                <div className="mb-4">
                  <h3 className="mb-4">Accedi a Joppys</h3>
                  <span>
                    Non hai un account?{" "}
                    <Link preventScrollReset={true} to="/business/signup">
                      Registrati!
                    </Link>
                  </span>
                </div>

                <Button onClick={handleGoogle} disabled={loading} size="lg" variant="light w-100">
                  <GoogleButtonLabel />
                </Button>
                <div className="d-inline-flex align-items-center w-100 my-2">
                  <hr className="w-100" />
                  <span className="w-50 text-center">o</span>
                  <hr className="w-100" />
                </div>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-2">
                    <FloatingLabel controlId="email" label="Email" className="mb-2">
                      <Form.Control
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="Email"
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <FloatingLabel label="Password" className="mb-2" controlId="floatingPassword">
                      <Form.Control
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Password"
                      />
                    </FloatingLabel>
                  </Form.Group>

                  {error && (
                    <Alert className="mb-2" variant="danger">
                      {error}
                    </Alert>
                  )}
                  <Button disabled={loading} variant="primary" className="w-100 mb-2" size="lg" type="submit">
                    Accedi
                  </Button>

                  <div className="mt-2 text-center">
                    <Link to="/business/recovery-password" preventScrollReset={true}>
                      Hai dimenticato la password?
                    </Link>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      </Padding>
    </>
  )
}
