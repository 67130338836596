import React from "react";
import Footer from "common/Footer";
import BusinessHeader from "routes/business/BusinessHeader";

export default function BusinessRoot(props) {
  return (
    <>
      <BusinessHeader />
      {props.children}
      <Footer />
    </>
  );
}
