import React from "react"
import { useAppBusinessContext } from "context/AppBusinessContext"
import { InputGroupBox } from "routes/business/app/generic_forms/GenericFormComponents"
import GenericForm from "routes/business/app/generic_forms/GenericForm"
import { setGenericFormField } from "routes/business/app/generic_forms/GenericFormUtils"
import {
  businessFieldNames,
  businessFormFields,
  initBusinessForm,
} from "routes/business/app/BusinessForm"
import { updateBusinessDraft } from "scripts/private"
import { Col, Row } from "react-bootstrap"

const formName = "info"
const fields = businessFormFields[formName]

function formBuilder(form, setForm, errors, disabled) {
  return (
    <>
      <Row>
        <Col xs="auto">
          <InputGroupBox
            disabled={disabled}
            value={form[businessFieldNames.title]}
            id={businessFieldNames.title}
            errors={errors}
            fields={fields}
            onChange={(e) =>
              setGenericFormField(e.target.id, e.target.value, setForm)
            }
          />
        </Col>
      </Row>
    </>
  )
}

export default function FormBusinessInfo() {
  // get form data
  const { draftDoc } = useAppBusinessContext()
  // drafDoc is initialized in the appContext, no need to check loading data

  // initialize the form with data
  let initialForm = initBusinessForm(draftDoc.data, formName)

  //
  async function saveHandler(form) {
    await updateBusinessDraft(draftDoc.id, form)
    return true
  }

  return (
    <GenericForm
      saveHandler={saveHandler}
      fields={fields}
      formBuilder={formBuilder}
      initialForm={initialForm}
    />
  )
}
