import { FormState, useAppBusinessContext } from "context/AppBusinessContext"
import { useAuth } from "context/AuthContext"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import {  Outlet, useNavigate } from "react-router-dom"
import LabelDiv from "routes/business/common/LabelDiv"

export default function SettingsGeneral() {
  const { userDoc, setFormStatus } = useAppBusinessContext()
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  console.log('Build SettingGeneral')
  return (
    <>
     <Outlet /> 
     {/* used for offcanvas */}
      <div className="mb-5">
          <h4 className="d-inline-block mb-4 me-3">Dati titolare account</h4>
          <Button variant="link"     onClick={() => {
          setFormStatus(FormState.editing)
          navigate("edit")
        }}>
            Modifica
          </Button>
        
        <LabelDiv label={"Nome"}>{userDoc.data?.busOwner?.name}</LabelDiv>
        <LabelDiv label={"Cognome"}>{userDoc.data?.busOwner?.surname}</LabelDiv>
        <LabelDiv label={"Telefono"}>{userDoc.data?.busOwner?.phone}</LabelDiv>
      </div>
     
    
      <div className="mb-5">
        <h4 className="mb-4">Dati accesso</h4>

        <LabelDiv label={"Email"}>{currentUser.email}</LabelDiv>
      </div>
    </>
  )
}
