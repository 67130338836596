import { useAppBusinessContext } from "context/AppBusinessContext"
import { useAuth } from "context/AuthContext"
import React from "react"
import { Col, Row } from "react-bootstrap"
import OffcanvasForm from "routes/business/app/generic_forms/OffcanvasForm"
import { InputGroupBox } from "routes/business/app/generic_forms/GenericFormComponents"
import GenericForm from "routes/business/app/generic_forms/GenericForm"
import {
  genericFormsFields,
  initGenericForm,
  setGenericFormField,
} from "routes/business/app/generic_forms/GenericFormUtils"
import {
  renderLoadingData,
  setUserBillingDoc,
  useUserBillingDoc,
} from "scripts/private"

const formName = "billing"
const title = "Dati fatturazione"
const fields = genericFormsFields[formName]

function formBuilder(form, setForm, errors) {
  function localInputGroup(id) {
    return (
      <InputGroupBox className="mb-3"
        fields={fields}
        id={id}
        errors={errors}
        onChange={(e) =>
          setGenericFormField(e.target.id, e.target.value, setForm)
        }
        value={form[id]}
      />
    )
  }

  return (
    <>
      <Row>
        <Col xs="auto" className="me-3">
          {localInputGroup("business_name")}
          {localInputGroup("sdi")}
          {localInputGroup("vat_number")}
          {localInputGroup("fiscal_code")}
        </Col>

        <Col xs="auto" className="me-3">
          <Row>
            <Col xs="auto">{localInputGroup("address_street")}</Col>
            <Col xs="auto">{localInputGroup("house_number")}</Col>
          </Row>
          <Row>
            <Col xs="auto">{localInputGroup("city")}</Col>
            <Col xs="auto">{localInputGroup("postal_code")}</Col>
          </Row>
          {localInputGroup("district")}
        </Col>
      </Row>
    </>
  )
}

export default function FormBilling() {
  // used in saveHandler
  const { currentUser } = useAuth()

  // get form data
  const billingDoc = useUserBillingDoc(currentUser.uid)

  async function saveHandler(form) {
    // the form has the same structure as the database object
    await setUserBillingDoc(currentUser.uid, form)
    return true
  }

  function buildForm () {
    // elaborate data to fill the form
    const rawData = billingDoc?.data

    // initialize the form with data
    const initialForm = initGenericForm(rawData, formName)

    return (
      <GenericForm
        saveHandler={saveHandler}
        fields={fields}
        formBuilder={formBuilder}
        initialForm={initialForm}
        offcanvas={true}
      />
    )
  }
  const loadingForm = renderLoadingData(
    billingDoc,
    buildForm,
    undefined,
    buildForm,
    () => <>Errore</>
  )

  return <OffcanvasForm title={title}>{loadingForm}</OffcanvasForm>
}
