import React, { useState } from "react"
import { FormState, useAppBusinessContext } from "context/AppBusinessContext"
import { Col, Row } from "react-bootstrap"
import {
  buildGenericFormErrors,
  checkNoErrors,
  scrollToError,
} from "routes/business/app/generic_forms/GenericFormUtils"
import EditCancelSaveButtons from "routes/business/app/generic_forms/EditCancelSaveButtons"
import { useNavigate } from "react-router-dom"

export default function GenericForm({
  fields,
  formBuilder,
  initialForm,
  saveHandler,
  cancelHandler,
  errorBuilder = buildGenericFormErrors,
  offcanvas = false,
}) {
  const navigate = useNavigate()

  let { formStatus, setFormStatus } = useAppBusinessContext()

  let disabled = formStatus !== FormState.editing

  const [form, setForm] = useState(initialForm)

  console.log("initialForm", initialForm)
  console.log("form", form)

  // relying on form values and generic fields object it builds and error object
  let errors = errorBuilder(form, fields)

  let content = formBuilder(form, setForm, errors, disabled)

  return (
    <>
      <Row>
        <Col>{content}</Col>
      </Row>
      <Row>
        <Col>
          <EditCancelSaveButtons
            status={formStatus}
            onCancel={() => {
              setFormStatus(FormState.idle)
              if (offcanvas) {
                navigate("..")
              } else {
                setForm(initialForm)
              }
            }}
            onEdit={() => setFormStatus(FormState.editing)}
            onSave={async () => {
              if (checkNoErrors(errors)) {
                const success = await saveHandler(form)

                if (success) {
                  setFormStatus(FormState.idle)
                  if (offcanvas) {navigate("..")}
                   else {navigate(".")}
                }
              } else {
                scrollToError()
              }
            }}
          />
        </Col>
      </Row>
    </>
  )
}
