import { FormState, useAppBusinessContext } from "context/AppBusinessContext"
import React from "react"
import { Button } from "react-bootstrap"
import { Outlet, useNavigate } from "react-router-dom"


export default function EditTeam() {
  const navigate = useNavigate()
  const { setFormStatus } = useAppBusinessContext()
  return (
    <div>
      <Button
        onClick={() => {
          setFormStatus(FormState.editing)
          navigate("new")
        }}
      >
        Aggiungi
      </Button>
      <Outlet />
    </div>
  )
}
