import { Form, InputGroup } from "react-bootstrap"

export function InputGroupBox({
  disabled,
  fields = {},
  // name,
  // innerRef,
  id,
  as,
  value,
  onChange,
  errors,
  support,
  controlProps,
  type,
  label,
  index,
  ...props
}) {
  type = type ?? fields[id]?.type ?? "text"
  label = label ?? fields[id]?.label ?? ""
  support = support ?? fields[id]?.support

  let id_indexed = id
  if (index >= 0) {
    id_indexed = `${id}__${index}`
  }

  let formControl = (
    <>
      <Form.Control
    
        disabled={disabled}
        // ref={innerRef}
        
        name={id}
        type={type}
        value={value}
        onChange={onChange}
        isInvalid={!!errors[id_indexed]}
        aria-describedby={`${id_indexed}_prepend`}
      />
      {/* {errors[id_indexed] && errors[id_indexed] !== " " && ( */}
        <Form.Control.Feedback type="invalid">
          {errors[id_indexed]}
        </Form.Control.Feedback>
      {/* )} */}
    </>
  )

  // support is the icon on the left of the input box
  // it needs to be wrapped
  if (support) {
    formControl = (
      <>
        <InputGroup hasValidation> {/* removing hasValidation would squared right end of the input with support */}
          <InputGroup.Text id={`${id_indexed}_prepend`}>
            {support}
          </InputGroup.Text>
          {formControl}
        </InputGroup>
      </>
    )
  }

  return (
    <Form.Group key={id_indexed} as={as} controlId={id_indexed} {...props}>
      <Form.Label>{label}</Form.Label>
      {formControl}
    </Form.Group>
  )
}

export function InputSwitchCheck({
  label,
  type,
  fields,
  id,
  as,
  checked,
  onChange,
  index,
  disabled,
  ...props
}) {
 label = label ?? fields[id]?.label ?? ""
  type = type ?? fields[id]?.type ?? "switch"

  let id_indexed = id
  if (index >= 0) {
    id_indexed = `${id}__${index}`
  }
  return (
    <Form.Check
      disabled={disabled}
      as={as}
      name={id}
      checked={checked}
      type={type}
      id={id_indexed}
      label={label}
      onChange={onChange}
      {...props}
    />
  )
}
