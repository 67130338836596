import { Alert, Badge, Button, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import React from "react"
import SpinnerCentered from "common/SpinnerCentered"
import { useAppBusinessContext } from "context/AppBusinessContext"
import BannerPremium from "common/BannerPremium"

export default function Dashboard() {
  console.log("Dashboard")
  const { draftDoc } = useAppBusinessContext()

  function renderCard(busDoc) {
    let content

    if (busDoc.data) {
      const docData = busDoc.data
      const isOnline = !!docData.urlId
      const waitCreate = !isOnline && docData.draft_status.id === "waiting"
      const waitUpdate = isOnline && docData.draft_status.id === "waiting"
      content = (
        <>
          <h4>{docData.title}</h4>
          <p className="mb-2">
            {isOnline && (
              <Badge bg="success" id="d_page_link" className="me-2">
                Online
              </Badge>
            )}
            {waitCreate && (
              <Badge bg="danger" className="me-2">
                In attesa di approvazione
              </Badge>
            )}
            {waitUpdate && (
              <Badge bg="warning" text="dark" className="me-2">
                Modifiche in attesta di approvazione
              </Badge>
            )}
          </p>

          {docData.urlId && (
            <p>
              <Link to={`/${docData.urlId}`} rel="noopener noreferrer">
                {`www.joppys.pet/${docData.urlId}`}
              </Link>
            </p>
          )}
          {isOnline && (
            <p>
              <i className="bi bi-check-lg me-2"></i>La tua pagina è visibile sulla mappa di Joppys e nei risultati di
              ricerca.
            </p>
          )}
          <p>
            <i className="bi bi-info-circle me-2"></i>Nelle prossime ore lo staff di Joppys verificherà le informazioni
            che ci hai fornito.
          </p>
          <Button to="/business/app/edit_business/" as={Link}>
            <i className="bi bi-pen me-2"></i>Modifica
          </Button>
        </>
      )
    } else {
      content = (
        <>
          <h4>Benvenuta/o in Joppys!</h4>
          <p>Crea in pochi passi la pagina gratuita per la tua attività</p>

          <Button to="/business/app/add_business" as={Link}>
            <i className="bi bi-plus me-2"></i>Crea pagina
          </Button>
        </>
      )
    }

    return (
      // <Card>
      //   <Card.Header as="h5">La tua pagina</Card.Header>
      //   <Card.Body>
      <>{content}</>
      // </Card.Body>
      //  </Card>
    )
  }

  return !draftDoc ? (
    <SpinnerCentered />
  ) : (
    <>
      <Row>
        <Col xs="auto">
          <Alert variant="warning">
          La piattaforma Joppys Business è in versione beta. Stiamo lavorando per fornire un servizio completo e di facile utilizzo. <br/>
          Se hai bisogno di aiuto o hai suggerimenti non esistare a contattarci.
          </Alert>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>{renderCard(draftDoc)}</Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <BannerPremium />
        </Col>
      </Row>
    </>
  )
}
