import React from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink /*, useNavigate*/ } from "react-router-dom";
import JoppysImage, { iLogoBusiness } from "img/JoppysImage";

export default function BusinessHeader(props) {
  // const { currentUser, logout } = useAuth();
  // const isLogged = currentUser && true;
  // // const navigate = useNavigate()

  // async function handleLogout() {
  //   try {
  //     await logout();
  //     // navigate("")
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  return (
    <>
      <Navbar bg="white" expand="md" className="border-bottom">
        <Container>
          <Navbar.Brand>
            <Link to="">
              <JoppysImage style={{ height: "50px" }} content={iLogoBusiness} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ms-auto">
              <Link to="/">
                <Button variant="secondary" className="mx-2">
                  Torna a Joppys
                </Button>
              </Link>
              <NavLink to={"/business/app"} className="nav-link">
                <i className="bi bi-person me-2"></i>
                Area professionisti
              </NavLink>
              {/* {isLogged ? (
                <>
                  <NavLink to={"app"} className="nav-link">
                    <i className="bi bi-person me-2"></i>
                    Il mio profilo
                  </NavLink>

                  <Button className="mx-2" onClick={handleLogout}>
                    Logout
                    <i className="bi bi-box-arrow-right ms-2"></i>
                  </Button>
                </>
              ) : (
                <NavLink to={"login"} className="nav-link">
                  <i className="bi bi-person me-2"></i>
                  Accedi
                </NavLink>
              )} */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
