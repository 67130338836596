import { useAuth } from "context/AuthContext"
import React from "react"
import BusinessRoot from "routes/business/BusinessRoot"
import LogIn from "routes/business/LogIn"

export default function PrivateAuthRoute({ children }) {
  const { currentUser } = useAuth()

  // non usare <Navigate > per riportare al login, perché fa confusione.
  // currentUser in un qualche momento risulta non settato, e facendo navigate al login si esce dal
  // context dell' Auth
  // return currentUser ? children : <Navigate to="/business/login" />
  return currentUser ? (
    children
  ) : (
    <>
      <BusinessRoot>
          <LogIn redirectToDashboadOnLogin={false} />
      </BusinessRoot>
    </>
  )
}
