import React, { useState } from "react"
import { Button, Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { submitContactForm } from "scripts/public"
import { validateEmail, validateLength } from "scripts/Validation"

export default function Contacts() {
  const [completed, setCompleted] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [form, setForm] = useState({})

  function setField(field, value) {
    setForm({
      ...form,
      [field]: value,
    })
  }

  let errors = {}
  if (submitted) {
    checkFormValid()
  }

  function checkFormValid() {
    const { email, message, service } = form
    const newErrors = {}

    if (!validateEmail(email)) newErrors.email = "Inserisci un indirizzo e-mail vaildo"
    if (!validateLength(service)) newErrors.service = "Seleziona un servizio"
    if (!validateLength(message)) newErrors.message = "Inserisci un messaggio"

    if (Object.keys(newErrors).length === 0) {
      return true
    } else {
      errors = newErrors
      return false
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (!checkFormValid()) {
      if (!submitted) setSubmitted(true)
      return
    }
    await submitContactForm(form)
    setCompleted(true)
  }

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col xl={8}>
          <Card className="rounded-4 p-4">
            {completed ? (
              <>
                <h3>Messaggio inviato!</h3>
                <div className="my-3">Grazie per averci contattato, il nostro team ti contatterà presto!</div>
                <Link to="/">Torna a Joppys</Link>
              </>
            ) : (
              <>
                <h3>Contattaci</h3>
                <h6 className="mb-4">Il nostro team ti risponderà entro 48 ore</h6>
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} md={8} lg={6} controlId="email">
                      <Form.Label>Indirizzo e-mail</Form.Label>
                      <InputGroup hasValidation>
                        <InputGroup.Text>@</InputGroup.Text>
                        <Form.Control
                          type="email"
                          onChange={(e) => setField("email", e.target.value)}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md={8} lg={6} controlId="service">
                      <Form.Label>Quale servizio stai utilizzando?</Form.Label>
                      <Form.Select
                        onChange={(e) => setField("service", e.target.value)}
                        isInvalid={!!errors.service}
                        defaultValue=""
                      >
                        <option value="">Scegli...</option>
                        <option value="web">Sito Web</option>
                        <option value="android">App Android</option>
                        <option value="ios">App iOS</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.service}</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="message">
                      <Form.Label>Messaggio</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        onChange={(e) => setField("message", e.target.value)}
                        isInvalid={!!errors.message}
                      />
                      <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                      <Form.Text>
                        Inserisci i dettagli della tua richiesta, sarai ricontattato dal nostro team.
                      </Form.Text>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col>
                      <Button type="submit">Invia</Button>
                    </Col>
                  </Row>
                </Form>
                <div className="d-none" id="d_success">
                  <div className="mb-3" id="d_response">
                    Grazie per il tuo messaggio, il nostro team ti contatterà presto!
                  </div>
                  <a href="/">Torna a Joppys</a>
                </div>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
