import { useAppBusinessContext } from "context/AppBusinessContext"
import { useAuth } from "context/AuthContext"
import React, { useState } from "react"
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Row,
  ToggleButton,
} from "react-bootstrap"
import { useLoaderData } from "react-router-dom"
import {
  createStripeCheckoutSession,
  onStripeCheckoutSessionSnapshot,
} from "scripts/private"

// function ProductItem({ product, price, onClick }) {
//   return (
//     <>
//       <div className="m-4">
//         <h4>{product.data.name}</h4>
//         <div className="mb-3">
//           {(price.data.unit_amount / 100).toLocaleString("it-IT")} € /{" "}
//           {price.data.interval}
//         </div>
//         <Button onClick={onClick}>Abbonati</Button>
//       </div>
//     </>
//   )
// }

function PriceCard({
  badgeText,
  selector,
  title,
  features,
  priceText,
  priceComment,
  onClick,
  ...other
}) {
  return (
    <>
      <Card {...other}>
        <div className="text-center">
          <Card.Body>

            {selector}

            <h4 className="mt-3">&nbsp;{title}&nbsp;</h4>
            {badgeText && (
              <>
                <Badge bg="secondary" text="dark" className="my-2">
                  {badgeText}
                </Badge>
                <br />
              </>
            )}
            <h1>{priceText}</h1>
            <p>{priceComment}</p>
            
            {features && (
              <>
                <p className="mt-5">Cosa comprende il piano?</p>
                <ul className="list-unstyled text-start">
                  {features?.map((e, index) => (
                    <div key={index} className="d-flex flex-row">
                      <div>
                        <i className="bi bi-check-lg me-2 text-primary" />
                      </div>
                      <div>{e}</div>
                    </div>
                  ))}
                </ul>
              </>
            )}
            {onClick && <Button onClick={onClick}>Acquista</Button>}
          </Card.Body>
        </div>
      </Card>
    </>
  )
}

function ProductToggler({ product, features, onClick }) {
  const intervals = {
    month: {
      label_radio: "Mensile",
      label_price: "al mese",
      label_recurrence: "mensilmente",
      label_badge: "",
    },
    year: {
      label_radio: "Annuale",
      label_price: "all'anno",
      label_recurrence: "annualmente",
      label_badge: "Risparmia il 16% con ilpiano annuale",
    },
  }
  let [interval, setInterval] = useState("month")

  let selectedPrice = product.prices.find((p) => p.data.interval === interval)

  return (
    <PriceCard
      title={product.name}
      badgeText={intervals[interval].label_badge}
      selector={
        <ButtonGroup>
          {Object.keys(intervals).map((key, idx) => (
            <ToggleButton
              className="btn-sm"
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant="outline-primary"
              name="radio"
              value={key}
              checked={interval === key}
              onChange={(e) => setInterval(e.currentTarget.value)}
            >
              {intervals[key].label_radio}
            </ToggleButton>
          ))}
        </ButtonGroup>
      }
      priceText={
        <>
          {(selectedPrice.data.unit_amount / 100).toLocaleString("it-IT")} € <span className="h4">{intervals[interval].label_price}</span>
        </>
      }
      priceComment={<>+ IVA, pagato {intervals[interval].label_recurrence}</>}
      features={features}
      onClick={() => onClick(selectedPrice.id)}
    />
  )
  return (
    <>
      <Card className="text-center">
        <Card.Body>
          <Badge bg="secondary" text="dark" className="mb-3">
            Risparmia il 16% con ilpiano annuale
          </Badge>
          <br />
          <ButtonGroup>
            {Object.keys(intervals).map((key, idx) => (
              <ToggleButton
                className="btn-sm"
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant="outline-primary"
                name="radio"
                value={key}
                checked={interval === key}
                onChange={(e) => setInterval(e.currentTarget.value)}
              >
                {intervals[key].label_radio}
              </ToggleButton>
            ))}
          </ButtonGroup>
          <h4 className="mt-3">{product.name}</h4>
          <h1>
            {(selectedPrice.unit_amount / 100).toLocaleString("it-IT")} €/
            {intervals[interval].label_price}
          </h1>
          <p>+ IVA, pagato {intervals[interval].label_recurrence}</p>
          <p>Cosa comprende il piano?</p>

          <ul className="list-unstyled text-start">
            {[
              "Scheda attività completa",
              "Visibilità nelle ricerche attraverso la nostra piattaforma",
              "Ricevi il nostro aiuto per tenere sempre aggiornatti i tuoi dati",
              "Fidelizza i clienti",
            ].map((e, index) => (
              <>
                <div className="d-flex flex-row">
                  <div>
                    <i className="bi bi-check-lg me-2 text-primary" />
                  </div>
                  <div>{e}</div>
                </div>
              </>
            ))}
          </ul>
          <Button onClick={onClick}>Acquista</Button>
        </Card.Body>
      </Card>
    </>
  )
}

export default function Plans() {
  const { currentUser } = useAuth()
  const { premiumType, setShowModalLoader } = useAppBusinessContext()
  const { productsPricesByType } = useLoaderData()
  const [checkoutSessionId, setCheckoutSessionId] = useState()

  if (checkoutSessionId) {
    onStripeCheckoutSessionSnapshot(
      currentUser.uid,
      checkoutSessionId,
      (snap) => {
        const { error, url } = snap.data
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${error.message}`)
        }
        if (url) {
          // We have a Stripe Checkout URL, let's redirect.
          window.location.assign(url)
        }
      }
    )
  }

  const buyPrice = async (priceId) => {
    setShowModalLoader(true)
    const checkoutSessionId = await createStripeCheckoutSession(
      currentUser.uid,
      priceId,
      window.location.origin +
        "/business/app/plans/success?session_id={CHECKOUT_SESSION_ID}",
      window.location.origin + "/business/app/plans/"
    )
    setCheckoutSessionId(checkoutSessionId)
  }

  return (
    <>
      <div className="text-center">
        <Row>
          <Col>
            <h2>Accresci la tua attività, scegli il piano Joppys premium</h2>
          </Col>
        </Row>
        <Row className="mt-3 mb-5">
          <Col>Il piano è disattivabile in qualsiasi momento.</Col>
        </Row>
      </div>
      <Row className="justify-content-center">
        <Col xs={12} sm={8} md={6} lg={4} xl={3} className="d-flex">
          <PriceCard
            className="flex-grow-1"
            priceText="Gratis"
            features={["Scheda base gratuita per la tua attività"]}
            priceComment="nessuna carta di pagamento richiesta"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={3}>
          <ProductToggler
            features={[
              "Scheda attività completa",
              "Visibilità nelle ricerche attraverso la nostra piattaforma",
              "Ricevi il nostro aiuto per tenere sempre aggiornatti i tuoi dati",
              "Fidelizza i clienti",
            ]}
            product={productsPricesByType["bus_basic"]}
            onClick={(priceId) => buyPrice(priceId)}
          />
        </Col>
      </Row>
    </>
  )
}
