import {
  Button,
  Col,
  Collapse,
  Container,
  Modal,
  Nav,
  Navbar,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap"
import { useAuth } from "context/AuthContext"
import { Link, Outlet, useLocation } from "react-router-dom"
import { useEffect, useState } from "react"

import JoppysImage, { iLogoBusiness } from "img/JoppysImage"
import { useAppBusinessContext } from "context/AppBusinessContext"
import MyNavItem from "routes/business/common/MyNavItem"
import { kEditFormLabel, kVisiblePages } from "routes/business/app/BusinessForm"

function AppBusiness(props) {
  const { currentUser, logout } = useAuth()
  const {
    draftDoc,
    ignoreEdit,
    continueEdit,
    showCEM,
    onChangeSection,
    showModalLoader,
  } = useAppBusinessContext()

  const [showSidebar, setShowSidebar] = useState(false)
  const [busMenuOpen, setBusMenuOpen] = useState(false)

  const { pathname } = useLocation()

  const handleCloseSidebar = () => setShowSidebar(false)
  const handleShowSidebar = () => setShowSidebar(true)

  async function handleLogout() {
    try {
      await logout()
      // navigate("")
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // opens sidebar menu depending on selected page
    if (pathname.startsWith("/business/app/edit_business")) {
      setBusMenuOpen(true)
    }
  }, [pathname])

  return (
    <>
      {showModalLoader && (
        <Modal
          show={showModalLoader}
          dialogAs={Spinner}
          className="d-flex align-items-center justify-content-center"
        ></Modal>
      )}
      <Modal show={showCEM} onHide={continueEdit} centered>
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Attenzione!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Non hai salvato le modifiche. Vuoi procedere senza salvare?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={continueEdit}>
            Torna alle modifiche
          </Button>
          <Button variant="primary" onClick={ignoreEdit}>
            Ignora le modifiche
          </Button>
        </Modal.Footer>
      </Modal>
      <Navbar bg="white" expand="md" className="border-bottom">
        <Container fluid>
          <Navbar.Brand>
            <Link to="/business/app">
              <JoppysImage style={{ height: "50px" }} content={iLogoBusiness} />
            </Link>
          </Navbar.Brand>
          <Navbar>
            <Button
              className="d-md-none border"
              variant="light"
              onClick={handleShowSidebar}
            >
              <span className="navbar-toggler-icon"></span>
            </Button>
          </Navbar>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          {/* SIDEBAR */}
          <Col md={3} lg={2} className="p-0" id="app-sidebar">
            <Offcanvas
              show={showSidebar}
              onHide={handleCloseSidebar}
              responsive="md"
              // style={{ minHeight: "calc(100vh - 77px)" }}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Dashboard</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="pt-md-3 flex-column">
                <Nav className="flex-column" variant="pills">
                  <MyNavItem
                    id="/business/app/dashboard"
                    onClick={onChangeSection}
                  >
                    <i className="bi bi-pie-chart me-2"></i>Dashboard
                  </MyNavItem>
                  {draftDoc.data && (
                    <>
                      <MyNavItem
                        type="toggle"
                        id="busMenu"
                        show={busMenuOpen}
                        onClick={() => setBusMenuOpen(!busMenuOpen)}
                      >
                        <i className="bi bi-briefcase me-2"></i>Attività
                      </MyNavItem>
                      {/* <Nav.Item className="d-flex flex-column">
                    <span
                      className="nav-link btn-toggle"
                      onClick={() => setBusMenuOpen(!busMenuOpen)}
                      aria-controls="busMenu"
                      aria-expanded={busMenuOpen}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="bi bi-briefcase me-2"></i>Attività
                    </span>
                  </Nav.Item> */}

                      <Collapse in={busMenuOpen} id="busMenu">
                        <div className="ms-4">
                          <div>
                            {
                            kVisiblePages[draftDoc.data.type].map((e) => (
                              <MyNavItem
                                key={e}
                                id={`/business/app/edit_business/${e}`}
                                onClick={onChangeSection}
                              >
                                {kEditFormLabel[e]}
                              </MyNavItem>
                            ))}
                          </div>
                        </div>
                      </Collapse>
                    </>
                  )}
                  <hr className="mx-0 my-1" />
                  <MyNavItem id="/business/app/plans" onClick={onChangeSection}>
                    <i className="bi bi-award me-2"></i>Premium
                  </MyNavItem>
                  {draftDoc.data && (
                    <MyNavItem
                      id="/business/app/settings/general"
                      onClick={onChangeSection}
                    >
                      <i className="bi bi-gear me-2"></i>Impostazioni
                    </MyNavItem>
                  )}
                  <MyNavItem type="button" onClick={handleLogout}>
                    <i className="bi bi-box-arrow-right me-2"></i>Esci
                  </MyNavItem>
                </Nav>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          {/* BODY */}
          <Col className="p-4">
            <Outlet context={{ currentUser, draftDoc }}>
              <Outlet />
            </Outlet>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AppBusiness
