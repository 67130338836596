import { useAppBusinessContext } from "context/AppBusinessContext"
import { useAuth } from "context/AuthContext"
import React from "react"
import OffcanvasForm from "routes/business/app/generic_forms/OffcanvasForm"
import { InputGroupBox } from "routes/business/app/generic_forms/GenericFormComponents"
import GenericForm from "routes/business/app/generic_forms/GenericForm"
import {
  genericFormsFields,
  initGenericForm,
  setGenericFormField,
} from "routes/business/app/generic_forms/GenericFormUtils"
import { updateUserDoc } from "scripts/private"

const formName = "busOwner"
const title = "Dati titolare account"
const fields = genericFormsFields[formName]

function formBuilder(form, setForm, errors) {
  function localInputGroup(id) {
    return (
      <InputGroupBox
        fields={fields}
        id={id}
        errors={errors}
        onChange={(e) =>
          setGenericFormField(e.target.id, e.target.value, setForm)
        }
        value={form[id]}
      />
    )
  }

  return (
    <>
      {localInputGroup("name")}
      {localInputGroup("surname")}
      {localInputGroup("phone")}
    </>
  )
}

export default function FormBusOwner() {
  // used in saveHandler
  const { currentUser } = useAuth()

  // get form data
  const { userDoc } = useAppBusinessContext()
  // userDoc is initialized in the appContext, no need to check loading data


  // elaborate data to fill the form
  const rawData = userDoc.data?.busOwner

  // initialize the form with data
  const initialForm = initGenericForm(rawData, formName)

  //
  async function saveHandler(form) {
    console.log(form)
    const newData = {
      "busOwner.name": form["name"],
      "busOwner.surname": form["surname"],
      "busOwner.phone": form["phone"],
    }
    await updateUserDoc(currentUser.uid, newData)
    return true
  }

  return (
    <OffcanvasForm title={title}>
      <GenericForm
        saveHandler={saveHandler}
        fields={fields}
        formBuilder={formBuilder}
        initialForm={initialForm}
        offcanvas={true}
      />
    </OffcanvasForm>
  )
}
