import { useEffect } from "react"
import { matchRoutes, useLocation } from "react-router-dom"


const titleRoutes = [
  {
    path: "/",
    title: "Joppys",
    children: [
      {
        path: "",
        title: "Home",
      },
      {
        path: "about",
        title: "Chi siamo",
      },
      {
        path: "contacts",
        title: "Contatti",
      },
      {
        path: "legal/privacy",
        title: "Privacy Policy",
      },
      {
        path: "newsletter/app",
        title: "App Newsletter",
      },
    ],
  },
  {
    path: "/business",
    title: "Joppys for Business",
    children: [
      {
        path: "",
        title: "Home",
      },
      {
        path: "dashboard",
        title: "Dashboard",
      },
      {
        path: "signup",
        title: "Registrati",
      },
      {
        path: "login",
        title: "Accedi",
      },
      {
        path: "recovery-password",
        title: "Recupera la password",
      },
    ],
  },
  {
    path: "/:businessId",
    title: "",
  },
]

export default function ScrollToTop(props) {
  const { pathname } = useLocation()

  useEffect(() => {
    const results = matchRoutes(titleRoutes, pathname)

    let title
    if (results) {
      results.forEach((e) => {
        if (e.route.title !== undefined || e.route.title === "") {
          title = e.route.title + (title ? " | " + title : "")
        }
      })
    }
    document.title = title || "Joppys"

    // risolto con ScrollRestoration del router
    // window.scrollTo({ top: 0, left: 0, behavior: "instant" })
    // preso da qua https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
    // con auto non funziona, rimane smooth, probabilmente perché nel css è settato questo, da controllare
    // html {
    //   scroll-behavior: smooth;
    // }
  }, [pathname])

  return props.children
}
