import SpinnerCentered from "common/SpinnerCentered"
import { FormState, useAppBusinessContext } from "context/AppBusinessContext"
import { useAuth } from "context/AuthContext"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { Outlet, useNavigate } from "react-router-dom"
import { genericFormsFields } from "routes/business/app/generic_forms/GenericFormUtils"
import LabelDiv from "routes/business/common/LabelDiv"
import {
  renderLoadingData,

  useUserBillingDoc,
} from "scripts/private"

export default function SettingsBilling() {
  const {  setFormStatus } = useAppBusinessContext()
  const { currentUser } = useAuth()
  const billingDoc = useUserBillingDoc(currentUser.uid)

  const navigate = useNavigate()

  const fields = genericFormsFields.billing

  let contentBilling = renderLoadingData(
    billingDoc,
    () => (
      <>
        <Row>
          <Col xs="auto" className="me-3">
            <LabelDiv label={fields.business_name.label}>
              {billingDoc.data?.business_name}
            </LabelDiv>
            <LabelDiv label={fields.sdi.label}>
              {billingDoc.data?.sdi}
            </LabelDiv>
            <LabelDiv label={fields.vat_number.label}>{billingDoc.data?.vat_number}</LabelDiv>
            <LabelDiv label={fields.fiscal_code.label}>
              {billingDoc.data?.fiscal_code}
            </LabelDiv>
          </Col>
          <Col xs="auto" className="me-3">
            <Row>
              <Col xs="auto">
                <LabelDiv label={fields.address_street.label}>
                  {billingDoc.data?.address_street}
                </LabelDiv>
              </Col>
              <Col xs="auto">
                <LabelDiv label={fields.house_number.label}>
                  {billingDoc.data?.house_number}
                </LabelDiv>
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                <LabelDiv label={fields.city.label}>{billingDoc.data?.city}</LabelDiv>
              </Col>
              <Col xs="auto">
                <LabelDiv label={fields.postal_code.label}>
                  {billingDoc.data?.postal_code}
                </LabelDiv>
              </Col>
            </Row>

            <LabelDiv label={fields.district.label}>{billingDoc.data?.district}</LabelDiv>
          </Col>
        </Row>
      </>
    ),
    () => <SpinnerCentered />,
    () => (
      <>
        <div>Dati fatturazione mancanti</div>
      </>
    ),
    () => <>C'è stato un errore nel caricamento dei dati di fatturazione</>
  )

  console.log("Build SettingsBilling")
  return (
    <>
      <Outlet />
      {/* used for offcanvas */}
      <div className="mb-5">
        <h4 className="d-inline-block mb-4 me-3">Dati fatturazione</h4>
        <Button
          variant="link"
          onClick={() => {
            setFormStatus(FormState.editing)
            navigate("edit")
          }}
        >
          Modifica
        </Button>
        {contentBilling}
      </div>
    </>
  )
}
