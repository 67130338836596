import Padding from "common/Padding"
import React, { useState } from "react"
import { Button, Card, Col, Container, InputGroup, Row, Form } from "react-bootstrap"
import { Link } from "react-router-dom"
import { subscribeNewsletter } from "scripts/public"
import { validateEmail, validateLength } from "scripts/Validation"

const linkToHome = <Link to="/">Torna a Joppys</Link>

function renderResult(result, form) {
  let title
  let desc
  switch (result) {
    case "ok":
      title = "Iscrizione riuscita!"
      desc = (
        <>
          Grazie {form.name}! Ti aggiorneremo al tuo indirizzo e-mail <b>{form.email}</b>
        </>
      )
      break
    case "permission-denied":
      title = "Errore!"
      desc = (
        <>
          L'indirizzo <b>{form.email}</b> risulta già iscritto alla newsletter!
        </>
      )
      break
    default:
      title = "Errore!"
      desc = result
      break
  }
  return (
    <>
      <h3>{title}</h3>

      <div className="my-3">{desc}</div>
      {linkToHome}
    </>
  )
}
export default function NewsletterApp() {
  const [result, setResult] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [form, setForm] = useState({})

  function setField(field, value) {
    setForm({
      ...form,
      [field]: value,
    })
  }

  let errors = {}
  if (submitted) {
    checkFormValid()
  }

  function checkFormValid() {
    const { email, name } = form
    const newErrors = {}

    if (!validateEmail(email)) newErrors.email = "Inserisci un indirizzo e-mail vaildo"
    if (!validateLength(name)) newErrors.name = "Inserisci il tuo nomoe"

    if (Object.keys(newErrors).length === 0) {
      return true
    } else {
      errors = newErrors
      return false
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!checkFormValid()) {
      if (!submitted) setSubmitted(true)
      return
    }
    try {
      await subscribeNewsletter(form)
      setResult("ok")
    } catch (e) {
      setResult(e.code || "?")
    }
  }

  return (
    <>
      <Padding>
        <Container className="py-5">
          <Row className="justify-content-center">
            <Col lg={8} xl={6} className="text-center">
              <Card className="rounded-4 p-4">
                {result && renderResult(result, form)}
                {!result && (
                  <>
                    <h3>Iscriviti alla newsletter di Joppys</h3>
                    {/* <div className="my-3">
                    <b>Joppys</b> sarà presto disponibile per il tuo dispositivo mobile Android e iOS.
                    <br />
                    Inserisci il tuo indirizzo e-mail e sarai il primo a sapere del suo lancio!
                  </div> */}
                    <div className="my-3">
                      <b>Joppys</b> sarà presto disponibile per altri professionisti del settore.
                      <br />
                      Inserisci il tuo indirizzo e-mail e sarai informato su tutte le novità e gli aggiornamenti!
                    </div>
                    <Form noValidate onSubmit={handleSubmit}>
                      <div className="d-grid gap-3">
                        <Row className="justify-content-center">
                          <Form.Group as={Col} md={10} lg={8} controlId="name">
                            <Form.Label>Il tuo nome</Form.Label>
                            <Form.Control
                              type="text"
                              onChange={(e) => setField("name", e.target.value)}
                              isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="justify-content-center">
                          <Form.Group as={Col} md={10} lg={8} controlId="email">
                            <Form.Label>Il tuo indirizzo e-mail</Form.Label>
                            <InputGroup hasValidation>
                              <InputGroup.Text>@</InputGroup.Text>
                              <Form.Control
                                type="email"
                                onChange={(e) => setField("email", e.target.value)}
                                isInvalid={!!errors.email}
                              />
                              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Button size="lg" type="submit">
                              Iscrivimi
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </Padding>
    </>
  )
}
