
import React from "react"

export default function BusinessCard() {
  React.useEffect(() => {
    window.location.replace('https://linktr.ee/diegomariotti')
  }, [])
}



