import React from "react"
import { Nav } from "react-bootstrap"
import { NavLink } from "react-router-dom"

export default function MyNavItem({ type, show, onClick, children, id, ...props }) {
  if (type === "toggle") {
    return (
      <>
        <Nav.Item className="d-flex flex-column">
          <span
            className="nav-link btn-toggle"
            onClick={onClick}
            aria-controls={id}
            aria-expanded={show}
            style={{ cursor: "pointer" }}
          >
            {children}
          </span>
        </Nav.Item>
      </>
    )
  } else if (type === "button") {
    return (
      <>
        <Nav.Item>
          <span className="nav-link" onClick={onClick} style={{ cursor: "pointer" }} {...props}>
            {children}
          </span>
        </Nav.Item>
      </>
    )
  } else {
    return (
      <>
        <Nav.Item>
          <NavLink className="nav-link" id={id} to={id} onClick={onClick}>
            {children}
          </NavLink>
        </Nav.Item>
      </>
    )
  }
}
