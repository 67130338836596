import React from "react"

export default function LabelDiv({ label, children }) {
  return (
    <div className="mb-3">
      <div className="mb-1"><b>{label}</b></div>
      {children}
    </div>
  )
}
