import React, { useEffect, useState } from "react"
import { useAppBusinessContext } from "context/AppBusinessContext"
import { InputGroupBox } from "routes/business/app/generic_forms/GenericFormComponents"
import GenericForm from "routes/business/app/generic_forms/GenericForm"
import {
  checkNoErrors,
  scrollToError,
  setGenericFormField,
} from "routes/business/app/generic_forms/GenericFormUtils"

import {
  businessFieldNames,
  businessFormFields,
  getSubSet,
  initBusinessForm,
} from "routes/business/app/BusinessForm"
import { updateBusinessDraft } from "scripts/private"
import { Button, Col, Form, Row } from "react-bootstrap"
import GoogleMapsApiWrapper, {
  geocodeAddress,
  Marker,
  MyMapComponent,
} from "common/GoogleMapsApi"
import JoppysImage, { iMapPin } from "img/JoppysImage"
import { Status } from "@googlemaps/react-wrapper"

const formName = "address"
const fields = businessFormFields[formName]

export default function FormBusinessAddress() {
  // get form data
  const { draftDoc } = useAppBusinessContext()
  // drafDoc is initialized in the appContext, no need to check loading data

  // initialize the form with data
  let initialForm = initBusinessForm(draftDoc.data, formName)

  //
  async function saveHandler(form) {

    await updateBusinessDraft(draftDoc.id, form)
    return true
  }

  return (
    <GenericForm
      saveHandler={saveHandler}
      fields={fields}
      formBuilder={OnlyForm}
      initialForm={initialForm}
    />
  )
}

function OnlyForm(form, setForm, errors, disabled) {
  let addressChanged = form[businessFieldNames.NO_DB_var_address_changed]
  let markerCalculated = form[businessFieldNames.NO_DB_var_marker_calculated]

  function localInputGroup(afterOnChange, id, props) {
    return (
      <InputGroupBox
        {...props}
        disabled={disabled}
        value={form[id]}
        id={id}
        errors={errors}
        fields={fields}
        onChange={(e) => {
          afterOnChange()
          //setGenericFormField(businessFieldNames.NO_DB_var_address_changed, true, setForm)
          setGenericFormField(e.target.id, e.target.value, setForm)
        }}
      />
    )
  }

  // DO NOT try to move geocoder into the form values state. geocoder is initialized on page load and resetting the form would also resetting geocoder
  // with the result that geocoder won't be initialized again
  const [geocoder, setGeocoder] = useState()

  useEffect(() => {
    if (addressChanged) {
      setGenericFormField(
        businessFieldNames.NO_DB_form_address_check,
        false,
        setForm
      )
    }
  }, [addressChanged, setForm])

  function setAddressChanged() {
    setGenericFormField(
      businessFieldNames.NO_DB_var_address_changed,
      true,
      setForm
    )
  }
  console.log("addresschanged", addressChanged)
  return (
    <>
      <Row>
        <Col xs="auto">
          <Row>
            {localInputGroup(
              setAddressChanged,
              businessFieldNames.address_street,
              { as: Col, xs: 8 }
            )}
            {localInputGroup(
              setAddressChanged,
              businessFieldNames.address_house_number,
              { as: Col, xs: 4 }
            )}
            {/* <InputGroupBox
              as={Col}
              xs={8}
              disabled={disabled}
              value={form[businessFieldNames.address_street]}
              id={businessFieldNames.address_street}
              errors={errors}
              fields={fields}
              onChange={(e) => {
                setGenericFormField(
                  businessFieldNames.NO_DB_var_address_changed,
                  true,
                  setForm
                )
                setGenericFormField(e.target.id, e.target.value, setForm)
              }}
            />
            <InputGroupBox
              as={Col}
              xs={4}
              disabled={disabled}
              value={form[businessFieldNames.address_house_number]}
              id={businessFieldNames.address_house_number}
              errors={errors}
              fields={fields}
              onChange={(e) => {
                setGenericFormField(
                  businessFieldNames.NO_DB_var_address_changed,
                  true,
                  setForm
                )
                setGenericFormField(e.target.id, e.target.value, setForm)
              }}
            /> */}
          </Row>
          <Row>
          {localInputGroup(
              setAddressChanged,
              businessFieldNames.address_city,
             
            )}
            {/* <InputGroupBox
              disabled={disabled}
              value={form[businessFieldNames.address_city]}
              id={businessFieldNames.address_city}
              errors={errors}
              fields={fields}
              onChange={(e) => {
                setGenericFormField(
                  businessFieldNames.NO_DB_var_address_changed,
                  true,
                  setForm
                )
                setGenericFormField(e.target.id, e.target.value, setForm)
              }}
            /> */}
          </Row>
          <Row>
          {localInputGroup(
              setAddressChanged,
              businessFieldNames.address_district,
              { as: Col, xs: 7 }
            )}
            {/* <InputGroupBox
              as={Col}
              xs={7}
              disabled={disabled}
              value={form[businessFieldNames.address_district]}
              id={businessFieldNames.address_district}
              errors={errors}
              fields={fields}
              onChange={(e) => {
                setGenericFormField(
                  businessFieldNames.NO_DB_var_address_changed,
                  true,
                  setForm
                )
                setGenericFormField(e.target.id, e.target.value, setForm)
              }}
            /> */}
            {localInputGroup(
              setAddressChanged,
              businessFieldNames.address_postal_code,
              { as: Col, xs: 5 }
            )}
            {/* <InputGroupBox
              as={Col}
              xs={5}
              disabled={disabled}
              value={form[businessFieldNames.address_postal_code]}
              id={businessFieldNames.address_postal_code}
              errors={errors}
              fields={fields}
              onChange={(e) => {
                setGenericFormField(
                  businessFieldNames.NO_DB_var_address_changed,
                  true,
                  setForm
                )
                setGenericFormField(e.target.id, e.target.value, setForm)
              }}
            /> */}
          </Row>
        </Col>
      </Row>
      {!disabled && (
        <Row>
          <Col className="mt-5">
            <div className="text-larger mb-2">
              Controlla la posizione sulla mappa!
            </div>
            <div>
              Per farti trovare è importante che la posizione della tua attività
              sia corretta. Posizioneremo il cursore in base all'indirizzo che
              ci hai fornito. Se la posizione non è esatta, o vuoi indicarla con
              più precisione, trascina il segnalino
              <JoppysImage content={iMapPin} className="mx-2" />
              {/* <img width="20" className="mx-2" alt="" src="google_maps_pin.svg" /> */}
              sulla mappa.
            </div>
          </Col>
        </Row>
      )}
      <Row className="mt-3">
        <Col className="mx-auto">
          <GoogleMapsApiWrapper
            callback={(status, loader) => {
              console.log("ApiWrapper")
              if (status === Status.SUCCESS) {
                loader.load().then((google) => {
                  !geocoder && setGeocoder(new google.maps.Geocoder())
                })
              }
            }}
          >
            <div className="position-relative">
              <MyMapComponent
                className="rounded-3"
                height={400}
                zoom={17}
                center={form[businessFieldNames.geo_location]}
                triggerReload={disabled} // used for rebuild map after "cancel". Because only moving map or zooming and cancel would not reinit the map in the center
              >
                {form[businessFieldNames.geo_location] && (
                  <Marker
                    draggable={!disabled}
                    position={form[businessFieldNames.geo_location]}
                    onDragEnd={(e) => {
                      setGenericFormField(
                        [businessFieldNames.geo_location],
                        e.latLng.toJSON(),
                        setForm
                      )
                    }}
                  />
                )}
              </MyMapComponent>

              {(addressChanged || !markerCalculated) && (
                <div>
                  <div className="position-absolute w-100 h-100 top-50 start-50 translate-middle bg-dark opacity-75"></div>
                  <div className="position-absolute top-50 start-50 translate-middle bg-light rounded-4 p-4 text-center">
                    <p>
                      {" "}
                      {markerCalculated ? (
                        <>
                          L'indirizzo è stato modificato. <br />
                          Ricalcola la posizione del cursore.
                        </>
                      ) : (
                        <>
                          Sulla base dell'indirizzo fornito verrà posizionato il
                          segnalino sulla mappa
                        </>
                      )}
                    </p>
                    <Button
                      onClick={(e) => {
                        console.log(getSubSet(errors, "address"))
                        if (checkNoErrors(getSubSet(errors, "address"))) {
                          const fullAddress = `${
                            form[businessFieldNames.address_street] ?? ""
                          }, ${
                            form[businessFieldNames.address_house_number] ?? ""
                          }, ${
                            form[businessFieldNames.address_postal_code] ?? ""
                          } ${form[businessFieldNames.address_city] ?? ""} ${
                            form[businessFieldNames.address_district] ?? ""
                          }, Italy`

                          geocodeAddress(geocoder, fullAddress, (location) => {
                            setGenericFormField(
                              businessFieldNames.geo_location,
                              location.toJSON(),
                              setForm
                            )
                            setGenericFormField(
                              businessFieldNames.NO_DB_var_address_changed,
                              false,
                              setForm
                            )
                            setGenericFormField(
                              businessFieldNames.NO_DB_var_marker_calculated,
                              true,
                              setForm
                            )
                          })
                        } else {
                          scrollToError()
                        }
                      }}
                    >
                      {markerCalculated ? "Aggiorna" : "Calcola posizione"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </GoogleMapsApiWrapper>
        </Col>
      </Row>
      {!disabled && (
        <Row className="mt-3">
          <Col lg={8} className="mx-auto d-flex justify-content-center">
            <div className="d-inline-flex">
              <Form.Check>
                <Form.Check.Input
                  checked={
                    form[businessFieldNames.NO_DB_form_address_check] ?? false
                  }
                  disabled={addressChanged || !markerCalculated}
                  onChange={
                    (e) =>
                      setGenericFormField(
                        businessFieldNames.NO_DB_form_address_check,
                        e.target.checked,
                        setForm
                      )
                    // setGenericFormField(businessFieldNames.NO_DB_form_address_check, e.target.checked, setForm)
                  }
                  isInvalid={
                    !!errors[businessFieldNames.NO_DB_form_address_check]
                  }
                />
                <Form.Check.Label>
                  Confermo la posizione indicata sulla mappa
                </Form.Check.Label>
              </Form.Check>
            </div>
          </Col>
        </Row>
      )}
    </>
  )
}
