import React from "react"
import { useAppBusinessContext } from "context/AppBusinessContext"
import { InputGroupBox, InputSwitchCheck } from "routes/business/app/generic_forms/GenericFormComponents"
import GenericForm from "routes/business/app/generic_forms/GenericForm"
import { setGenericFormField } from "routes/business/app/generic_forms/GenericFormUtils"
import {
  businessFieldNames,
  businessInitialFields,
  BusinessTypes,
  busPetTypes,
  initBusinessForm,
} from "routes/business/app/BusinessForm"
import { updateBusinessDraft } from "scripts/private"
import { Col,  Row } from "react-bootstrap"

const formName = "medical_services"
const fields = {
  [businessFieldNames.more_pet_type_small]: businessInitialFields[businessFieldNames.more_pet_type_small],
  [businessFieldNames.more_pet_type_horse]: businessInitialFields[businessFieldNames.more_pet_type_horse],
  [businessFieldNames.more_pet_type_exotic]: businessInitialFields[businessFieldNames.more_pet_type_exotic],
  [businessFieldNames.more_pet_type_farm]: businessInitialFields[businessFieldNames.more_pet_type_farm],
}

function formBuilder(form, setForm, errors, disabled) {
  return (
    <>
      <Row className="my-3 text-larger">
        <Col>Animali trattati</Col>
      </Row>
      <Row>
        <Col >
          {busPetTypes[BusinessTypes.VET].map((e) => (
            <InputSwitchCheck
              checked={form[`more.pet_type.${e}`]}
              disabled={disabled}
              fields={fields}
              key={`pet_type_${e}`}
              id={`more.pet_type.${e}`}
              onChange={(e) => setGenericFormField(e.target.id, e.target.checked, setForm)}
            />
          ))}
        </Col>
      </Row>
    </>
  )
}

export default function FormBusinessVetMedicalServices() {
  // get form data
  const { draftDoc } = useAppBusinessContext()
  // drafDoc is initialized in the appContext, no need to check loading data

  // initialize the form with data
  let initialForm = initBusinessForm(draftDoc.data, formName)

  //
  async function saveHandler(form) {
    await updateBusinessDraft(draftDoc.id, form)
    return true
  }

  return <GenericForm saveHandler={saveHandler} fields={fields} formBuilder={formBuilder} initialForm={initialForm} />
}
