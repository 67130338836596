import React from "react"
import { Badge, Button, Col, Container, Row } from "react-bootstrap"
import JoppysImage, { icBusinessServices } from "img/JoppysImage"
import { Link } from "react-router-dom"
import { BusinessTypes } from "routes/business/app/BusinessForm"


export default function BusinessHome() {
  return (
    <Container fluid className="p-0">
      <div className="bg-primary">
        <Container>
          <Row className="justify-content-center py-5">
            <Col className="align-self-center text-center mt-4 mb-3">
              <h1 className="mb-4">
                La trasformazione digitale del <b>pet care</b> è iniziata.
                <br />
                Hai bisogno di un alleato per il tuo lavoro.
              </h1>
              <div className="text-larger mb-4">
                Dalla creazione del tuo spazio personale online, alla fidelizzazione dei tuoi clienti.
                <br />
                Crea il profilo della tua attività in pochi passi.
              </div>
              <Link to={"/business/login"}>
                <Button  variant="secondary" size="lg" type="button" className="mt-4 px-5">
                  Unisciti a Joppys
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>

        <div className="bg-light">
          <Container className="text-center py-5">
            <Row className="justify-content-center py-3">
              <Col sm={8} lg={6}>
                <h1 className="mb-4">
                  Perché <b>Joppys</b> per un professionista?
                </h1>
                <div className="text-larger">
                  Scopri le potenzialità di Joppys, come può aiutarti nel tuo lavoro e come può farti risparmiare tempo
                  e denaro
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center gx-3 gx-md-5 gy-4 py-3">
              <Col sm={6}>
                <div className="bg-body rounded-4 p-3">
                  <div className="text-end">
                    <Badge bg="success" className=" text-normal">
                      Free
                    </Badge>
                  </div>
                  <div className="px-2 px-lg-5">
                    {/* <img src="/img/rect.svg" className="w-50" alt="Annota e archivia" /> */}
                    <div className="pt-3">
                      <h4>Fatti conoscere</h4>
                      <div className="text-larger">
                        Entra a far parte della piattaforma creando gratuitamente il tuo profilo base
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="bg-body rounded-4 p-3">
                  <div className="text-end">
                    <Badge bg="warning" text="dark" className="text-normal">
                      Starter
                    </Badge>
                  </div>
                  <div className="px-2 px-lg-5">
                    {/* <img src="/img/rect.svg" className="w-50" alt="Annota e archivia" /> */}
                    <div className="pt-3">
                      <h4>Rafforza il tuo brand</h4>
                      <div className="text-larger">
                        Arricchisci il tuo profilo, per rafforzare il rapporto con i tuoi clienti e trovarne di nuovi
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="bg-body rounded-4 p-3">
                  <Row className="justify-content-end">
                    <Col xs="auto">
                      <Badge bg="warning" text="dark" className="text-normal">
                        Premium
                      </Badge>
                      <br />
                      Coming soon
                    </Col>
                  </Row>
                  <div className="px-2 px-lg-5">
                    {/* <img src="/img/rect.svg" className="w-50" alt="Annota e archivia" /> */}
                    <div className="pt-3">
                      <h4>Fidelizza i tuoi clienti</h4>
                      <div className="text-larger">
                        Tieni aggiornati i tuoi clienti, promuovi le tue offerte, invia promemoria per i tuoi
                        appuntamenti
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="bg-body rounded-4 p-3">
                  <Row className="justify-content-end">
                    <Col xs="auto">
                      <Badge bg="warning" text="dark" className="text-normal">
                        Premium
                      </Badge>
                      <br />
                      Coming soon
                    </Col>
                  </Row>
                  <div className="px-2 px-lg-5">
                    {/* <img src="/img/rect.svg" className="w-50" alt="Annota e archivia" /> */}
                    <div className="pt-3">
                      <h4>Prenotazioni</h4>
                      <div className="text-larger">
                        Ricevi prenotazioni 24/24h attraverso un sistema semplice e intuitivo, organizza meglio il tuo
                        tempo
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center mt-5 py-3">
              <Col sm={10} lg={8}>
                <h1 className="mb-4">Un'unica piattaforma per tutto il mondo del pet</h1>
                <div className="text-larger">
                  I nostri servizi sono specificatamente progettati per veterinari e toelettatori, ma il nostro lavoro
                  non si fermerà qui e stiamo già lavorando per offrire spazio anche a tutti gli altri professionisti
                  del settore
                </div>
              </Col>
              <Row className="justify-content-center gx-3 gx-md-5 gy-4 mt-2">
                <Col sm={6} lg={4}>
                  <JoppysImage content={icBusinessServices[BusinessTypes.VET]} className="w-50" />
                  <h3 className="mt-3">Veterinari</h3>
                </Col>
                <Col sm={6} lg={4}>
                  <JoppysImage content={icBusinessServices[BusinessTypes.GROOMER]} className="w-50" />
                  <h3 className="mt-3">Toelettatori</h3>
                </Col>
                <Col sm={10}>
                  Sei un altro professionista/attività del settore o vuoi semplicemente ricevere gli aggiornamenti sulle
                  novità della piattaforma?
                  <br />
                  <Link to="/business/newsletter">Iscriviti alla newsletter.</Link>
                </Col>
              </Row>
            </Row>

            <Container className="text-center bg-body"></Container>
          </Container>
        </div>
      </div>
    </Container>
  )
}
