/** @format */

import React from "react"
import { useAppBusinessContext } from "context/AppBusinessContext"
import { InputGroupBox, InputSwitchCheck } from "routes/business/app/generic_forms/GenericFormComponents"
import GenericForm from "routes/business/app/generic_forms/GenericForm"
import { setGenericFormField } from "routes/business/app/generic_forms/GenericFormUtils"
import {
  businessFieldNames,
  businessInitialFields,
  BusinessTypes,
  busPetTypes,
  initBusinessForm,
} from "routes/business/app/BusinessForm"
import { updateBusinessDraft } from "scripts/private"
import { Col, Form, Row } from "react-bootstrap"

const formName = "services"
const fields = {
  [businessFieldNames.more_home_service]: businessInitialFields[businessFieldNames.more_home_service],
  [businessFieldNames.more_home_service_range]: businessInitialFields[businessFieldNames.more_home_service_range],
  [businessFieldNames.more_pet_type_cat]: businessInitialFields[businessFieldNames.more_pet_type_cat],
  [businessFieldNames.more_pet_type_dog]: businessInitialFields[businessFieldNames.more_pet_type_dog],
  [businessFieldNames.more_pet_type_rabbit]: businessInitialFields[businessFieldNames.more_pet_type_rabbit],
}

function formBuilder(form, setForm, errors, disabled) {
  return (
    <>
      <Row className="my-3 text-larger">
        <Col>Servizio a domicilio</Col>
      </Row>
      <Row className="align-items-center">
        <Col xs="auto">
          <InputSwitchCheck
            disabled={disabled}
            fields={fields}
            checked={form["more.home_service"]}
            id="more.home_service"
            onChange={(e) => {
              setGenericFormField(e.target.id, e.target.checked, setForm)
              if (!e.target.checked) {
                setGenericFormField("more.home_service_range", 0, setForm)
              } else {
                setGenericFormField("more.home_service_range", 10, setForm)
              }
            }}
          />
        </Col>
        <Col xs="auto">
          <Form.Select
            disabled={disabled || !form["more.home_service"]}
            id="more.home_service_range"
            value={form["more.home_service_range"]}
            aria-label="Raggio"
            onChange={(e) => setGenericFormField(e.target.id, parseInt(e.target.value), setForm)}
          >
            <option value={0}>{fields["more.home_service_range"].label}</option>
            {[5, 10, 20, 30, 40, 50].map((e) => (
              <option key={`home_service_range_${e}`} value={e}>
                {e} km
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <hr className="my-4 border-primary" />
      <Row className="my-3 text-larger">
        <Col>Animali trattati</Col>
      </Row>
      <Row>
        <Col>
          {busPetTypes[BusinessTypes.TRAINER].map((e) => (
            <InputSwitchCheck
              checked={form[`more.pet_type.${e}`]}
              disabled={disabled}
              fields={fields}
              key={`pet_type_${e}`}
              id={`more.pet_type.${e}`}
              onChange={(e) => setGenericFormField(e.target.id, e.target.checked, setForm)}
            />
          ))}
        </Col>
      </Row>
    </>
  )
}

export default function FormBusinessTrainerServices() {
  // get form data
  const { draftDoc } = useAppBusinessContext()
  // drafDoc is initialized in the appContext, no need to check loading data

  // initialize the form with data
  let initialForm = initBusinessForm(draftDoc.data, formName)

  //
  async function saveHandler(form) {
    await updateBusinessDraft(draftDoc.id, form)
    return true
  }

  return <GenericForm saveHandler={saveHandler} fields={fields} formBuilder={formBuilder} initialForm={initialForm} />
}
