import {
  collection,
  query,
  where,
  getDocs,
  serverTimestamp,
  addDoc,
  doc,
  updateDoc,
  onSnapshot,
  orderBy,
  limit,
  setDoc,
} from "firebase/firestore"
import { db, firebaseFunctions } from "scripts/FirebaseApp"

import { useEffect, useState } from "react"
import SpinnerCentered from "common/SpinnerCentered"
import { httpsCallable } from "firebase/functions"

const businessDraftPath = "business_draft"
const userPath = "users"

export class CodeError extends Error {
  constructor(message, code) {
    super(message)
    this.code = code
  }

  static notFound = "not-found"

  isNotFound() {
    return this.code === CodeError.notFound
  }
}

// create nested object from flat object with dot notation
// docRaw is the flat object to convert
// tag is the tag to remove from the flat object, it used for conditional parameters of the form not to be stored
export function convertFromDotNotation(docRaw, tag) {
  const separator = "."
  const asArray = Object.entries(docRaw)
  // filter out tag
  const filtered = asArray.filter(
    ([key, value]) => !key.startsWith(tag + separator)
  )

  docRaw = Object.fromEntries(filtered)

  let doc = {}
  for (const keyRaw in docRaw) {
    const myArray = keyRaw.split(separator)

    let obj = doc
    let len = myArray.length
    for (let i = 0; i < len - 1; i++) {
      if (myArray[i].endsWith("[]")) {
        myArray[i] = myArray[i].substring(0, myArray[i].length - 2)
        if (!obj[myArray[i]]) {
          obj[myArray[i]] = []
        }
      } else {
        if (!obj[myArray[i]]) {
          obj[myArray[i]] = {}
        }
      }
      obj = obj[myArray[i]]
    }
    obj[myArray[len - 1]] = docRaw[keyRaw]
  }
  console.log(doc)
  return doc
}

export async function updateUserDoc(uid, userDoc) {
  console.log("updating users/" + uid)

  const docRef = doc(db, userPath, uid)
  return updateDoc(docRef, userDoc, { merge: true })
}

export async function setUserBillingDoc(uid, billingDoc) {
  billingDoc.date = serverTimestamp()
  console.log("new users/" + uid + "/billing/")

  const colRef = collection(db, userPath, uid, "billing")
  return addDoc(colRef, billingDoc)
}

export async function createBusinessDraft(uid, businessDraftDoc) {
  console.log(businessDraftDoc)
  const docData = convertFromDotNotation(businessDraftDoc, "form")

  // owner data are saved into busOwner field in user document
  const busOwnerData = {
    "busOwner.name": docData.owner.name,
    "busOwner.surname": docData.owner.surname,
    "busOwner.phone": docData.owner.phone,
  }

  await updateUserDoc(uid, busOwnerData)

  // owner data are saved
  delete docData.owner

  // phone.primary and phone.secondary are converted to an unique array phone,
  // bool 'main' indentifies the primary phone
  docData.phone.primary.main = true

  let phoneArray = [docData.phone.primary]
  if (docData.phone.secondary?.num?.length > 0) {
    phoneArray.push(docData.phone.secondary)
  }

  delete docData.phone

  docData.phones = phoneArray

  docData.address.geo.hash = ""

  docData.ownerId = uid
  docData.draft_status = {
    id: "waiting",
    date_create: serverTimestamp(),
  }
  console.log(docData)

  const newColRef = collection(db, businessDraftPath)
  await addDoc(newColRef, docData)
}

// single
export async function getBusinessDraft(uid) {
  const c = collection(db, businessDraftPath)
  const q = query(c, where("ownerId", "==", uid))
  const qs = await getDocs(q)

  if (qs.empty)
    throw new CodeError(
      `Documento con ownerId "${uid}" non esiste`,
      CodeError.notFound
    )
  return {
    id: qs.docs[0].id,
    data: qs.docs[0].data(),
  }
}

// when useBusinessDraftDoc null, loading
// when useBusinessDraftDoc {}, not-found or other errors
// when useBusinessDraftDoc .data, document exist and ready
export function useBusinessDraftDocSingle(uid) {
  const [draftDoc, setDraftDoc] = useState()

  useEffect(() => {
    getBusinessDraft(uid)
      .then((fbDoc) => setDraftDoc(fbDoc))
      .catch((e) => {
        if (e instanceof CodeError && e.isNotFound()) {
        }
        setDraftDoc({})
      })
  }, [uid])

  return draftDoc
}

export function useBusinessDraftDoc(uid) {
  const [draftDoc, setDraftDoc] = useState()

  useEffect(() => {
    const c = collection(db, businessDraftPath)
    const q = query(c, where("ownerId", "==", uid))
    const unsub = getCollectionSnapshotFirst(q, (doc) => setDraftDoc(doc))
    return () => unsub()
  }, [uid])

  return draftDoc
}

export function useStripeSubsList(uid) {
  const [list, setList] = useState()

  useEffect(() => {
    const c = collection(db, "stripe_customers", uid, "subscriptions")

    const q = query(c, where("status", "in", ["active", "trialing"]))
    const unsub = getCollectionSnapshot(q, (l) => setList(l))
    return () => unsub()
  }, [uid])

  return list
}

// generic function for retrieve document from firebase and handle dasta
// when undefined, loading
// when {}, not-found
// when .data, document exist and ready
// when false, error
export function getDocumentSnapshot(q, callback) {
  return onSnapshot(
    q,
    (qs) => {
      // console.log("getDocumentSnapshot: onSnapshot")
      if (qs.empty) {
        callback({})
      } else {
        callback({
          id: qs.id,
          data: qs.data(),
        })
      }
    },
    (error) => {
      console.log("Error on snapshot:", error)
      callback(false)
    }
  )
}

// generic function for retrieve first element of a collection from firebase and handle dasta
// when null, loading
// when {}, not-found or other errors
// when .data, document exist and ready
// when false, error
export function getCollectionSnapshotFirst(q, callback) {
  return onSnapshot(
    q,
    (qs) => {
      // console.log("getSnaposhot: onSnapshot")
      if (qs.empty) {
        callback({})
      } else {
        callback({
          id: qs.docs[0].id,
          data: qs.docs[0].data(),
        })
      }
    },
    (error) => {
      console.log("Error on snapshot:", error)
      callback(false)
    }
  )
}

// generic function for retrieve a collection from firebase and handle dasta
// when null, loading
// when [], empty
// when , document exist and ready
// when false, error
export function getCollectionSnapshot(q, callback) {
  return onSnapshot(
    q,
    (qs) => {
      // console.log("getSnaposhot: onSnapshot")
      if (qs.empty) {
        callback([])
      } else {
        callback(
          Array.from(qs.docs, (d) => ({
            id: d.id,
            data: d.data(),
          }))
        )
      }
    },
    (error) => {
      console.log("Error on snapshot:", error)
      callback(false)
    }
  )
}

export function useUserDoc(uid) {
  const [userDoc, setUserDoc] = useState()

  // console.log("useUserDoc")
  useEffect(() => {
    const dr = doc(db, userPath, uid)
    const unsub = getDocumentSnapshot(dr, (doc) => setUserDoc(doc))
    return unsub
  }, [uid])

  return userDoc
}

export function cleanFromTag(docRaw, tag) {
  const separator = "."
  const asArray = Object.entries(docRaw)
  // filter out tag
  const filtered = asArray.filter(
    ([key, value]) => !key.startsWith(tag + separator)
  )

  docRaw = Object.fromEntries(filtered)
  return docRaw
}

export async function updateBusinessDraft(docId, businessDraft) {
  console.log("updating doc: " + docId)
  console.log(businessDraft)
  const docData = cleanFromTag(businessDraft, "NO_DB")
  console.log(docData)

  // https://firebase.google.com/docs/firestore/manage-data/add-data#update_fields_in_nested_objects
  docData["draft_status.id"] = "waiting"
  docData["draft_status.date_edit"] = serverTimestamp()
  console.log(docData)
  const docRef = doc(db, businessDraftPath, docId)
  return updateDoc(docRef, docData, { merge: true })
}

export function useUserBillingDoc(uid) {
  const [billingDoc, setBillingDoc] = useState()

  useEffect(() => {
    const c = collection(db, "users", uid, "billing")
    const q = query(c, orderBy("date", "desc"), limit(1))
    const unsub = getCollectionSnapshotFirst(q, (d) => setBillingDoc(d))

    return () => unsub()
    // }
  }, [uid])

  return billingDoc
}

export async function createStripeCheckoutSession(
  uid,
  priceId,
  successUrl,
  cancelUrl
) {
  const c = collection(db, "stripe_customers", uid, "checkout_sessions")
  let newDocRef = await addDoc(c, {
    price: priceId,
    success_url: successUrl,
    cancel_url: cancelUrl,
  })

  console.log(newDocRef)

  return newDocRef.id
}

export function useStripeCheckoutSession(uid, sessionId) {
  const [sessDoc, setSessDoc] = useState()

  useEffect(() => {
    if (!uid || !sessionId) {
      setSessDoc(false)
    } else {
      const c = collection(db, "stripe_customers", uid, "checkout_sessions")
      const q = query(c, where("sessionId", "==", sessionId))
      const unsub = getCollectionSnapshotFirst(q, (d) => setSessDoc(d))
      return () => unsub()
    }
  }, [uid, sessionId])

  return sessDoc
}

export function onStripeCheckoutSessionSnapshot(uid, sessionId, callback) {
  const dr = doc(db, "stripe_customers", uid, "checkout_sessions", sessionId)
  return getDocumentSnapshot(dr, callback)
}

export async function callableFunctionGetStripePortalLink(returnUrl) {
  try {
    const getStripePortalLink = httpsCallable(
      firebaseFunctions,
      "ext-firestore-stripe-payments-createPortalLink"
    )
    const { data } = await getStripePortalLink({ returnUrl: returnUrl })
    return data.url
  } catch (error) {
    console.log(error)
    return false
  }
}

// // Wait for the CheckoutSession to get attached by the extension
// docRef.onSnapshot((snap) => {
//   const { error, url } = snap.data()
//   if (error) {
//     // Show an error to your customer and
//     // inspect your Cloud Function logs in the Firebase console.
//     alert(`An error occured: ${error.message}`)
//   }
//   if (url) {
//     // We have a Stripe Checkout URL, let's redirect.
//     window.location.assign(url)
//   }
// })

export function renderLoadingData(
  obj,
  render,
  renderLoading = () => <SpinnerCentered />,
  renderNotFound = () => <>Not found</>,
  renderError = () => <>Error</>
) {
  if (obj === false) {
    console.log("error")
    return renderError()
  }
  if (!obj) {
    console.log("loading")
    return renderLoading()
  }
  if (!obj?.data) {
    console.log("not found")
    return renderNotFound()
  }
  if (obj?.data) {
    console.log("found")
    return render()
  }
}
