import { useAppBusinessContext } from "context/AppBusinessContext"
import { validateLength } from "scripts/Validation"
import { useUserBillingDoc } from "scripts/private"

export const genericFormsFields = {
  busOwner: {
    name: {
      init: "",
      label: "Nome",
      type: "text",
      validate: true,
      error: "Campo obbligatorio",
    },
    surname: {
      init: "",
      label: "Cognome",
      type: "text",
      validate: true,
      error: "Campo obbligatorio",
    },
    phone: {
      init: "",
      label: "Telefono",
      type: "tel",
      validate: true,
      error: "Campo obbligatorio",
      support: <i className="bi bi-telephone"></i>,
    },
  },
  billing: {
    business_name: {
      init: "",
      label: "Nome / Ragione sociale",
      type: "text",
      validate: true,
      error: "Campo obbligatorio",
    },
    sdi: {
      init: "",
      label: "Codice SDI (fattura elettronica)",
      type: "text",
      validate: true,
      error: "Campo obbligatorio",
    },
    vat_number: {
      init: "",
      label: "P. IVA",
      type: "text",
      validate: true,
      error: "Campo obbligatorio",
    },
    fiscal_code: {
      init: "",
      label: "Codice fiscale",
      type: "text",
      validate: true,
      error: "Campo obbligatorio",
    },
    address_street: {
      init: "",
      label: "Via",
      type: "text",
      validate: true,
      error: "Campo obbligatorio",
    },
    house_number: {
      init: "",
      label: "Numero civico",
      type: "text",
      validate: true,
      error: "Campo obbligatorio",
    },
    city: {
      init: "",
      label: "Città",
      type: "text",
      validate: true,
      error: "Campo obbligatorio",
    },
    postal_code: {
      init: "",
      label: "CAP",
      type: "text",
      validate: true,
      error: "Campo obbligatorio",
    },
    district: {
      init: "",
      label: "Provincia",
      type: "text",
      validate: true,
      error: "Campo obbligatorio",
    },
  },
}

export function defaultValueGenericForm(formName) {
  console.log("defaultValueGenericForm", formName)
  let form = {}
  Object.keys(genericFormsFields[formName]).forEach((key) => {
    form[key] = genericFormsFields[formName][key].init
  })
  return form
}

export function initGenericForm(data, formName) {
  console.log("initGenericForm", formName)
  if (!data) return defaultValueGenericForm(formName)
  switch (formName) {
    case "busOwner":
      return {
        name: data?.name,
        surname: data?.surname,
        phone: data?.phone,
      }
    case "billing":
      return {
        business_name: data?.business_name,
        sdi: data?.sdi,
        vat_number: data?.vat_number,
        fiscal_code: data?.fiscal_code,
        address_street: data?.address_street,
        house_number: data?.house_number,
        city: data?.city,
        postal_code: data?.postal_code,
        district: data?.district,
      }
    default:
      return {}
  }
}

export function setGenericFormField(field, value, setForm) {
  console.log(field, value)
  setForm((currentForm) => {
    return {
      ...currentForm,
      [field]: value,
    }
  })
}

export function setGenericFormArrayField(
  arrayName,
  field,
  index,
  value,
  setForm
) {
  console.log(field, index, value)

  setForm((currentForm) => {
    const newArray = JSON.parse(JSON.stringify(currentForm[arrayName])) // need deep copy, otherwise nested object are copied by reference
    newArray[index][field] = value
    return { ...currentForm, [arrayName]: newArray } // return currentForm won't work. See this: https://stackoverflow.com/a/71250303/17818249
  })
}

// https://medium.com/@saphieabayomi/create-a-dynamic-form-in-react-5f7dbdd56a69
// capire se possono essere utili funzioni del genre

export function pushGenericFormArray(arrayName, value, setForm) {
  setForm((currentForm) => {
    const newArray = currentForm[arrayName].concat(value)
    return { ...currentForm, [arrayName]: newArray }
  })
}

export function popGenericFormArray(arrayName, index, setForm) {
  setForm((currentForm) => {
    const newArray = [...currentForm[arrayName]]
    console.log(newArray)
    newArray.splice(index, 1)
    return { ...currentForm, [arrayName]: newArray }
  })
}

export function buildGenericFormErrors(obj, fields) {
  let locErrors = {}
  Object.keys(fields).forEach((key) => {
    if (fields[key].validate) {
      let error = buildGenericError(obj[key], fields[key])
      if (error) locErrors[key] = error
    }
  })
  return locErrors
}

export function buildGenericError(val, field) {
  let error
  switch (field.type) {
    case "radio":
      if (val === null) error = field.error
      break
    case "bool":
      if (!val) error = field.error
      break
    default:
      if (!validateLength(val, field.minLength, true)) error = field.error
      break
  }
  return error
}

export function checkNoErrors(errors) {
  if (Object.keys(errors).length === 0) {
    return true
  } else {
    return false
  }
}

export function scrollToError() {
  const invalidFields = document.getElementsByClassName("is-invalid")
  if (invalidFields.length > 0) {
    invalidFields[0].scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    })
  }
}
// export const useGenericFetchData = (formName) => {
//   switch (formName) {
//     case "busOwner":
//       return useAppBusinessContext
//     case "billing":
//       return useUserBillingDoc
//     default:
//       // Handle default or error case
//       return () => {} // Or throw an error
//   }
// }
