import { useAppBusinessContext } from "context/AppBusinessContext"
import React from "react"
import { Offcanvas } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

export default function OffcanvasForm(props) {
  const { onClosingOffcanvasForm } = useAppBusinessContext()
  const navigate = useNavigate()

  // const handleClose = (e) => {
  //   onClosingOffcanvasForm(e)
  //   // navigate("..")
  // }
  return (
    <>
      <Offcanvas
        show
        onHide={() => onClosingOffcanvasForm(() => navigate(".."))}
        className="offcanvas-size-xxl"
        placement="end"
        backdrop="static"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{props.title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{props.children}</Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
