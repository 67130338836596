import React from "react"
import { Card } from "react-bootstrap"

export default function InfoCard({children, ...other}) {
  return (
    <Card bg="secondary" className="text-center" {...other}>
      <Card.Body className="d-flex">
        <i className="bi bi-info-circle me-4 align-self-center"></i>
        <div className="m-0 flex-fill">
          {children}
        </div>
      </Card.Body>
    </Card>
  )
}
