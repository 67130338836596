import { useAppBusinessContext } from "context/AppBusinessContext"
import { useAuth } from "context/AuthContext"
import React from "react"
import { Button, Col, Nav, Row, Tab } from "react-bootstrap"
import { NavLink, Outlet } from "react-router-dom"
import { callableFunctionGetStripePortalLink } from "scripts/private"

export default function Settings() {
  const { currentUser } = useAuth()
  const { premiumType, setShowModalLoader } = useAppBusinessContext()

  const onHandlePlanClick = async () => {
    setShowModalLoader(true)
    const stripePortalUrl = await callableFunctionGetStripePortalLink(window.location.origin + "/business/app/settings")
    if (stripePortalUrl) {
      window.location.assign(stripePortalUrl)
    } else {
      alert("Errore nella creazione del link portale, riprova")
    }
  }

  const isPremium = premiumType > 0

  return (
    <>
    <Tab.Container>
          <Row>
            <Col>
              <Nav id="app-sidebar" variant="pills">
                <NavLink
                  className="nav-link"
                  id="/business/app/settings/general"
                  to="/business/app/settings/general"
                  // onClick={onChangeSection}
                >
                  Generali
                </NavLink>
                <NavLink
                  className="nav-link"
                  id="/business/app/settings/billing"
                  to="/business/app/settings/billing"
                  // onClick={onChangeSection}
                >
                  Piano e fatturazione
                </NavLink>
              </Nav>
            </Col>
          </Row>
          <Row className="p-4">
            <Col>
              <Outlet/>
            </Col>
          </Row>
        </Tab.Container>
   
        {isPremium && <Button onClick={onHandlePlanClick}>Gestisci abbonamento</Button>}
   
    </>
  )
}
