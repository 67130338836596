import React from "react"
import { useAppBusinessContext } from "context/AppBusinessContext"
import { BusinessTypes } from "routes/business/app/BusinessForm"
import FormBusinessGroomerServices from "routes/business/app/edit/FormBusinessGroomerServices"
import FormBusinessVetServices from "routes/business/app/edit/FormBusinessVetServices"
import FormBusinessTrainerServices from "routes/business/app/edit/FormBusinessTrainerServices"
import FormBusinessBreederServices from "routes/business/app/edit/FormBusinessBreederServices"



export default function FormBusinessServices() {
  // get form data
  const { draftDoc } = useAppBusinessContext()

  const type = draftDoc.data.type
  
  if (type === BusinessTypes.VET) {
    return <FormBusinessVetServices/>
  } else if (type === BusinessTypes.GROOMER) {
    return <FormBusinessGroomerServices/>
  } else  if (type === BusinessTypes.TRAINER) {
    return <FormBusinessTrainerServices/>
  } else  if (type === BusinessTypes.BREEDER) {
    return <FormBusinessBreederServices/>
  }
  
}
