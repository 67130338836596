import { useAuth } from "context/AuthContext"
import React from "react"
import { Navigate, useSearchParams } from "react-router-dom"
import { renderLoadingData, useStripeCheckoutSession } from "scripts/private"

export default function SubscriptionSuccess() {
  let [searchParams] = useSearchParams()
  const sessionId = searchParams.get("session_id")
  const { currentUser } = useAuth()
  const checkoutSession = useStripeCheckoutSession(currentUser.uid, sessionId)

  return renderLoadingData(
    checkoutSession,
    () => <>Grazie! La tua iscrizione è stata completata con successo!</>,
    undefined,
    undefined,
    () => <Navigate to={"/business/app"} />
  )
}
