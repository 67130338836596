import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAVPv0iYxMLbFxEJsk3hSIa3ErBFd5Zet0",
  appId: "1:629598618013:web:6c25aab61aa4bb17aa6f74",
  authDomain: "auth.joppys.pet",
  // "authDomain": "joppys-3cfd5.firebaseapp.com",
  databaseURL: "https://joppys-3cfd5.firebaseio.com",
  measurementId: "G-X3FSYTCVX9",
  messagingSenderId: "629598618013",
  projectId: "joppys-3cfd5",
  storageBucket: "joppys-3cfd5.appspot.com",
}

export const firebaseApp = initializeApp(firebaseConfig)
export const db = getFirestore(firebaseApp)
export const firebaseFunctions = getFunctions(firebaseApp, 'europe-west6');
