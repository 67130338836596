import AwaitNavigate from "common/AwaitNavigate"
import React from "react"
import { Col, Nav, Row, Tab } from "react-bootstrap"
import { useAppBusinessContext } from "context/AppBusinessContext"
import { Outlet, useLocation } from "react-router-dom"
import { kEditFormLabel, kVisiblePages } from "routes/business/app/BusinessForm"
import MyNavItem from "routes/business/common/MyNavItem"

export default function EditBusiness() {
  console.log("render EditBusiness")
  const { onChangeSection, draftDoc } = useAppBusinessContext()

  const { pathname } = useLocation()
  console.log("eeee", pathname)
  let content
  if (!draftDoc.data) {
    // refused
    // no business
    content = (
      <div>
        Non hai ancora aggiunto la tua attività!
        <br />
        Verrai reindirizzato alla{" "}
        <AwaitNavigate to="/business/app" seconds={2}>
          dashboard
        </AwaitNavigate>
        .
        <br />
      </div>
    )
  } else {
    const visiblePages = kVisiblePages[draftDoc.data.type]

    let route = pathname.split("/business/app/edit_business/").pop().split("/")[0]

    if (route !== '' && !visiblePages.includes(route)) {
      content = (
        <div>
          Ops! Non hai i permessi per accedere a questa pagina.
          <br />
          Verrai reindirizzato alla{" "}
          <AwaitNavigate to="/business/app" seconds={2}>
            dashboard
          </AwaitNavigate>
          .
          <br />
        </div>
      )
    } else {
      // ok
      content = (
        <>
          <Tab.Container>
            <Row>
              <Col>
                <Nav id="app-sidebar" variant="pills">
                  {visiblePages.map((e) => (
                    <MyNavItem key={e} id={`/business/app/edit_business/${e}`} onClick={onChangeSection}>
                      {kEditFormLabel[e]}
                    </MyNavItem>
                  ))}
                </Nav>
              </Col>
            </Row>
            <Row className="p-4">
              <Col>
                <Outlet />
              </Col>
            </Row>
          </Tab.Container>
        </>
      )
    }
  }

  return <>{content}</>
}
