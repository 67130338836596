import JoppysImage, { icHome } from "img/JoppysImage"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

function BannerPremium() {
  return (
    <>
        <Row>
          <Col>
            <h4>Accresci la tua attività, scopri i vantaggi di Joppys premium</h4>
            <p>Con le opzioni premium potrai arricchire la tua pagina e trovare più facilmente nuovi clienti.</p>
          </Col>
        </Row>
        <Row>
          <Col xs={10} sm={6} md={3}>
            <JoppysImage content={icHome.JoppysBusiness} className="w-100" />
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
          <Button to="/business/app/plans" as={Link}><i className="bi bi-award me-2"></i>Scopri i vantaggi di premium</Button>
          </Col>
        </Row>
    </>
  )
}

export default BannerPremium
