import { Wrapper, Status } from "@googlemaps/react-wrapper"
import SpinnerCentered from "common/SpinnerCentered"
import { Children, cloneElement, isValidElement, useEffect, useRef, useState } from "react"


const ITALY_BOUNDS = {
  north: 47.5,
  south: 35.5,
  west: 6,
  east: 19,
}

const defaultLocation = { lat: 41.91, lng: 12.48 }

function initMap(ref, location = defaultLocation, zoom = 6, markerDraggable = true, markerTitle = "") {
  const map = new window.google.maps.Map(ref, {
    restriction: {
      latLngBounds: ITALY_BOUNDS,
      strictBounds: false,
    },
    minZoom: 4,
    zoom: zoom,
    center: location,
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    controlSize: 24,
    // styles: [
    //   {
    //     featureType: "poi.business",
    //     stylers: [
    //       {
    //         visibility: "off",
    //       },
    //     ],
    //   },
    //   {
    //     featureType: "poi.park",
    //     elementType: "labels.text",
    //     stylers: [
    //       {
    //         visibility: "off",
    //       },
    //     ],
    //   },
    // ],
    // mapTypeId: "hybrid",
    // disableDefaultUI: true,
  })

  return map
}

export function MyMapComponent({ onClick, height, center, zoom, children, triggerReload, ...props }) {
  center ??= defaultLocation
  height ??= 300
  if (!center) {
    zoom = 5
  }
  const ref = useRef()
  const [map, setMap] = useState()

  useEffect(() => {
    console.log('Use Effect' ,ref)
    if (ref.current && !map) {
      console.log("init map")
      const newMap = initMap(ref.current, center, zoom)
      setMap(newMap)
    } else {
      console.log("update map")
      map.setZoom(zoom)
      map.panTo(center)
    }
  }, [map, center, zoom, triggerReload])

  useEffect(() => {
    if (map) {
      let events = ["click", "idle"]
      events.forEach((eventName) => window.google.maps.event.clearListeners(map, eventName))
      if (onClick) {
        map.addListener("click", onClick)
      }
    }
  }, [map, onClick])

  return (
    <>
      <div className={props.className} ref={ref} style={{ height: `${height}px` }} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { map })
        }
      })}
    </>
  )
}

export function Marker({ onDragEnd, ...options }) {
  const [marker, setMarker] = useState()

  useEffect(() => {
    if (marker) {
      console.log("update marker")
      marker.setOptions({
        ...options,
        animation: options.draggable ? window.google.maps.Animation.BOUNCE : window.google.maps.Animation.DROP,
      })
    } else {
      console.log("set marker")
      setMarker(
        new window.google.maps.Marker({
          animation: options.draggable ? window.google.maps.Animation.BOUNCE : window.google.maps.Animation.DROP,
        })
      )
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [options, marker])

  useEffect(() => {
    if (marker) {
      let events = ["dragend"]
      events.forEach((eventName) => window.google.maps.event.clearListeners(marker, eventName))
      if (onDragEnd) {
        marker.addListener("dragend", onDragEnd)
      }
    }
  }, [marker, onDragEnd])
}

const render = (status) => {
  if (status === Status.FAILURE) return <>Error</>
  return <SpinnerCentered />
}

export default function GoogleMapsApiWrapper({ children, callback }) {
  return (
    <Wrapper apiKey="AIzaSyAVPv0iYxMLbFxEJsk3hSIa3ErBFd5Zet0" callback={callback} render={render}>
      {children}
    </Wrapper>
  )
}

export async function geocodeAddress(geocoder, formattedAddress, callback) {
  // var address = document.getElementById('address').value;
  let address = formattedAddress
  await geocoder.geocode({ address: address }, function (results, status) {
    if (status === "OK") {
      callback(results[0].geometry.location)
    } else {
      alert("Geocode was not successful for the following reason: " + status)
    }
  })
}
