import { useAuth } from "context/AuthContext"
import React, { useState, useContext, useEffect, createContext } from "react"
import {
  updateUserDoc,
  useBusinessDraftDoc,
  useStripeSubsList,
  useUserDoc,
} from "scripts/private"
import SpinnerCentered from "common/SpinnerCentered"

import { Timestamp, serverTimestamp } from "firebase/firestore"
import { useNavigate } from "react-router-dom"

const AppBusinessContext = createContext()

export const FormState = {
  idle: 0,
  editing: 1,
  saving: 2,
}

export function useAppBusinessContext() {
  return useContext(AppBusinessContext)
}

// in questo componente faccio tutti i caricamenti necessari per i valori comuni

export function AppBusinessContextProvider({ children }) {
  const { currentUser } = useAuth()
  const draftDoc =  useBusinessDraftDoc(currentUser.uid)
  const userDoc = useUserDoc(currentUser.uid)
  const [formStatus, setFormStatus] = useState(FormState.idle)
  const [showCEM, setShowCEM] = useState(false) // CEM: Cancel Edit Modal. Modal popup with Ignore, Confirm action changing page during edit 
  const [showModalLoader, setShowModalLoader] = useState(false) // Undismissible Modal Loader used for disable the app while loading for action to complete
  const [onIgnoreEdit, setOnIgnoreEdit] = useState()
  const navigate = useNavigate()

  const stripeSubsList = useStripeSubsList(currentUser.uid)
  // const stripeSubsList = undefined

  let premiumType = 0

  if (Array.isArray(stripeSubsList) && stripeSubsList.length) {
    if (
      stripeSubsList.some(
        (e) => e.data.items[0].price.product.metadata.type === "bus_basic"
      )
    ) {
      premiumType = 1
    } else if (
      stripeSubsList.some(
        (e) => e.data.items[0].price.product.metadata.type === "bus_premium"
      )
    ) {
      premiumType = 2
    }
  }

  const onClosingOffcanvasForm = (onComplete) => {
    if (formStatus === FormState.editing) {
      console.log(typeof onComplete)
      setOnIgnoreEdit(() => () => onComplete())

      handleCEMShow()
    } else if (formStatus === FormState.idle) {
      onComplete()
    }
  }

  const onChangeSection = (e) => {
    console.log("onChangeSection")
    if (formStatus === FormState.editing) {
      e.preventDefault()
      setOnIgnoreEdit(() => () => {
        navigate(e.target.id)
      })

      // setNextPage(e.target.id)
      // console.log(e.target.id)
      handleCEMShow()
    } else if (formStatus === FormState.idle) {
      // navigate(e.target.id);
    }
  }

  const ignoreEdit = () => {
    onIgnoreEdit()
    setFormStatus(FormState.idle)
    setShowCEM(false)
  }
  const continueEdit = () => setShowCEM(false)
  const handleCEMShow = () => setShowCEM(true)

  useEffect(() => {
    // check if the user is new and set as business account
    // also set latestAccessWeb if latest is before 5 minutes ago
    if (userDoc?.data) {
      let userDocMerge = {}
      // console.log("check user")
      // if not already business and isNewUser set isBusiness
      let isNewUser =
        currentUser.metadata.lastLoginAt === currentUser.metadata.createdAt
      if (!userDoc.data.isBusiness && isNewUser) {
        // isNewUser
        userDocMerge.isBusiness = true
      }

      // latestAccessWeb in initialized by the cloud function at 1 January 1999
      // this avoid the infinite loop updating a field not present
      if (userDoc.data.latestAccessWeb) {
        let nowMillis = Timestamp.now().toMillis()
        let latestAndOffset =
          userDoc.data.latestAccessWeb.toMillis() + 5 * 60 * 1000 // latestAccess + 5 minutes
        if (latestAndOffset < nowMillis) {
          userDocMerge.latestAccessWeb = serverTimestamp()
        }
      }
      if (Object.keys(userDocMerge).length !== 0) {
        updateUserDoc(currentUser.uid, userDocMerge)
      }
    }
  }, [userDoc, currentUser])

  const value = {
    userDoc,
    draftDoc,
    formStatus,
    setFormStatus,
    onChangeSection,
    onClosingOffcanvasForm,
    showCEM,
    ignoreEdit,
    continueEdit,
    premiumType,
    showModalLoader,
    setShowModalLoader
  }

  // console.log("drafDoc",!!draftDoc)
  // console.log("userDoc",!!userDoc)
  // console.log("stripeSubsList",!!stripeSubsList)

  if (!draftDoc | !userDoc | !stripeSubsList) {
    return (
      <>
        <div className="d-flex justify-content-center min-height-100vh">
          <SpinnerCentered />
        </div>
      </>
    )
  } else {
    return (
      <AppBusinessContext.Provider value={value}>
        {children}
      </AppBusinessContext.Provider>
    )
  }
  // return <AppBusinessContext.Provider value={value}>{!loading && children}</AppBusinessContext.Provider>

  // // useEffect(() => {

  // //   const unsubscribe = firebaseCheckUser((user) => {
  // //     setCurrentUser(user)
  // //     setLoading(false)
  // //   })

  // //   return unsubscribe
  // // }, [])

  // const value = { variable1, setVariable1, variable2, setVariable2 }
  // return <AppBusinessContext.Provider value={value}>{!loading && children}</AppBusinessContext.Provider>
}
